import {useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../context/auth.context';
import {useDispatch} from 'react-redux';
import {userEntryPass} from '../redux/userSlice';
import styles from './entryPage.module.css';

const EntryPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const {isLoggedIn} = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/home');
    }
  }, [isLoggedIn, history]);

  const handleSubmit = e => {
    e.preventDefault();
    if (password === 'eduvadi') {
      dispatch(userEntryPass(true));
      history.push('/home');
    } else {
      setError('You entered a wrong password');
    }
  };

  if (isLoggedIn) {
    history.push('/home'); // or you could return a loading spinner here
  }

  return (
    <div className={styles.entryPage}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Enter password"
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Enter
        </button>
        {error && <p className={styles.error}>{error}</p>}
      </form>
    </div>
  );
};

export default EntryPage;
