import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './breadcrumbs.module.css';

const Breadcrumbs = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const getDisplayName = (path, index, fullPath) => {
    // Handle special cases based on URL structure
    if (path === 'Leagues' && fullPath[index + 1]) {
      return t('breadcrumbs.leagueDetails');
    }
    if (path === 'see-league' && fullPath[index + 1]) {
      return t('breadcrumbs.seeLeague');
    }
    if (path === 'league-user-bets' && fullPath[index + 3]) {
      return `${decodeURIComponent(fullPath[index + 2])} ${t('breadcrumbs.bets')}`;
    }
    if (path === 'competitions') {
      return t('breadcrumbs.competitions');
    }
    if (path === 'bet') {
      return t('breadcrumbs.bet');
    }

    // Default translations for common paths
    const translations = {
      home: t('breadcrumbs.home'),
      leagues: t('breadcrumbs.leagues'),
      doubles: t('breadcrumbs.doubles'),
      'create-league': t('breadcrumbs.createLeague'),
      'all-leagues': t('breadcrumbs.allLeagues'),
      'my-leagues': t('breadcrumbs.myLeagues'),
      'all-doubles': t('breadcrumbs.allDoubles'),
      'my-doubles': t('breadcrumbs.myDoubles'),
      competitions: t('breadcrumbs.competitions'),
      deposit: t('breadcrumbs.deposit'),
      withdraw: t('breadcrumbs.withdraw'),
      profile: t('breadcrumbs.profile'),
      'about-us': t('breadcrumbs.aboutUs'),
    };

    return translations[path] || path;
  };

  return (
    <nav className={styles.breadcrumbs} aria-label="breadcrumb">
      {/*    <Link to="/" className={styles.breadcrumbLink}>
        {t('breadcrumbs.home')}
      </Link> */}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        // Skip IDs and numeric paths in the breadcrumb display
        if (value.match(/^[0-9a-fA-F]{24}$/) || /^\d+$/.test(value))
          return null;

        return last ? (
          <span key={to} className={styles.breadcrumbLast}>
            {getDisplayName(value, index, pathnames)}
          </span>
        ) : (
          <Link key={to} to={to} className={styles.breadcrumbLink}>
            {getDisplayName(value, index, pathnames)}
          </Link>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
