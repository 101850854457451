import {useTranslation} from 'react-i18next';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import Card from '../../components/card/Card';
import styles from './homeDoubles.module.css';
import nuevaLiga from './nuevaLiga.png';
import doubles from './doubles.png';

function HomeDoubles() {
  const {t} = useTranslation();

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div>
        <>
          <div className={styles.container}>
            <main className={styles.main}>
              <div className={styles.contentWrapper}>
                <div className={styles.contentTitle}>
                  <h1>{t('homeDoubles.welcome')}</h1>
                </div>
                <div className={styles.cardContainer}>
                  <Card
                    imageSrc={nuevaLiga}
                    altText={t('homeDoubles.newDouble.title')}
                    title={t('homeDoubles.newDouble.title')}
                    description={t('homeDoubles.newDouble.description')}
                    source="Games"
                    btnText={t('homeDoubles.newDouble.button')}
                    link="/home/doubles/create-double"
                  />
                  <Card
                    imageSrc={doubles}
                    altText={t('homeDoubles.myDoubles.title')}
                    title={t('homeDoubles.myDoubles.title')}
                    description={t('homeDoubles.myDoubles.description')}
                    source="Games"
                    btnText={t('homeDoubles.myDoubles.button')}
                    link="/home/doubles/my-doubles"
                  />
                  <Card
                    imageSrc={doubles}
                    altText={t('homeDoubles.allDoubles.title')}
                    title={t('homeDoubles.allDoubles.title')}
                    description={t('homeDoubles.allDoubles.description')}
                    source="Games"
                    btnText={t('homeDoubles.allDoubles.button')}
                    link="/home/doubles/all-doubles"
                  />
                </div>
              </div>
            </main>
          </div>
        </>
      </div>
    </>
  );
}

export default HomeDoubles;
