import {useState, useContext, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {AuthContext} from '../../context/auth.context';
import {useDispatch} from 'react-redux';
import {
  setUserMoney,
  userName,
  userToken,
  userId,
  setIsNewUser,
} from '../../redux/userSlice';
import liga from '../HomePage/assets/ligas.png';
import qa from '../HomePage/assets/qa.jpg';
import styles from './signupPage.module.css';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  userName: '',
  email: '',
  password: '',
  securityWord: '',
  showPassword: false,
  isOver18: false,
  errorMessage: undefined,
};

const SECURITY_WORDS = ['betandfriends', 'sports', 'betting'];

const SignupPage = () => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const {logInUser} = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback(e => {
    const {name, value, type, checked} = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }, []);

  const validateField = useCallback(
    (name, value) => {
      switch (name) {
        case 'userName':
          if (!value) return t('signup.validation.requiredUsername');
          if (value.length > 12) return t('signup.validation.usernameTooLong');
          return '';
        case 'email':
          return value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? ''
            : t('signup.validation.invalidEmail');
        case 'password':
          return value && value.length >= 6
            ? ''
            : t('signup.validation.passwordLength');
        case 'securityWord':
          return SECURITY_WORDS.includes(value.toLowerCase())
            ? ''
            : t('signup.validation.invalidSecurityWord');
        case 'isOver18':
          return value ? '' : t('signup.validation.ageRequired');
        default:
          return '';
      }
    },
    [t]
  );

  const handleBlur = useCallback(
    e => {
      const {name, value} = e.target;
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: validateField(name, value),
      }));
    },
    [validateField]
  );

  const togglePasswordVisibility = e => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleScrollClick = useCallback(() => {
    document.getElementById('ctaSection')?.scrollIntoView({behavior: 'smooth'});
  }, []);

  const handleSignupSubmit = useCallback(
    async e => {
      e.preventDefault();
      setIsSubmitting(true);

      const newErrors = Object.keys(formData).reduce((acc, key) => {
        acc[key] = validateField(key, formData[key]);
        return acc;
      }, {});

      if (Object.values(newErrors).some(error => error)) {
        setErrors(newErrors);
        setIsSubmitting(false);
        return;
      }

      if (!formData.isOver18) {
        setErrors(prev => ({
          ...prev,
          isOver18: t('signup.validation.ageRequired'),
        }));
        setIsSubmitting(false);
        return;
      }

      if (!SECURITY_WORDS.includes(formData.securityWord.toLowerCase())) {
        setErrors(prev => ({
          ...prev,
          securityWord: t('signup.validation.invalidSecurityWord'),
        }));
        setIsSubmitting(false);
        return;
      }

      try {
        const userData = {
          userName: formData.userName,
          email: formData.email,
          password: formData.password,
        };

        const response = await axios.post(`${API_URL}/auth/signup`, userData);
        const {authToken, user} = response.data;

        await new Promise(resolve => setTimeout(resolve, 500));

        logInUser({token: authToken, user});
        dispatch(userName(user.userName));
        dispatch(userToken(authToken));
        dispatch(userId(user._id));
        dispatch(setUserMoney(user.coins));
        dispatch(setIsNewUser(true));
        history.push('/home');
      } catch (error) {
        setFormData(prevState => ({
          ...prevState,
          errorMessage: error.response?.data?.message || 'An error occurred',
        }));
      } finally {
        setIsSubmitting(false);
      }
    },
    [formData, validateField, t, logInUser, dispatch, history]
  );

  return (
    <div className={styles.App}>
      <div className={styles.languageSwitcherContainer}>
        <Link to="/qa">
          <img src={qa} alt="Q&A" className={styles.qaIcon} />
        </Link>
        <LanguageSwitcher />
      </div>
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <p>{t('signup.hero.description')}</p>
        </div>
        <div className={styles.scrollIndicator} onClick={handleScrollClick}>
          <span>{t('signup.form.scroll')}</span>
          <div className={styles.scrollLine}></div>
        </div>
      </section>
      <section className={styles.cta} id="ctaSection">
        <div className={styles.ctaContent}>
          <h1>{t('signup.form.title')}</h1>
          <h3>{t('signup.form.subtitle')}</h3>
          <form onSubmit={handleSignupSubmit}>
            <div className={styles.ctaForm}>
              <input
                className={styles.ctaButton}
                type="text"
                name="userName"
                placeholder={t('signup.form.username')}
                value={formData.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={12}
              />
              {errors.userName && (
                <span className={styles.errorMessage}>{errors.userName}</span>
              )}
            </div>
            <div className={styles.ctaForm}>
              <input
                className={styles.ctaButton}
                type="email"
                name="email"
                placeholder={t('signup.form.email')}
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && (
                <span className={styles.errorMessage}>{errors.email}</span>
              )}
            </div>
            <div className={styles.ctaForm}>
              <div className={styles.passwordContainer}>
                <input
                  className={styles.ctaButton}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder={t('signup.form.password')}
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className={styles.passwordIcon}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.password && (
                <span className={styles.errorMessage}>{errors.password}</span>
              )}
            </div>
            <div className={styles.ctaForm}>
              <input
                className={styles.ctaButton}
                type="text"
                name="securityWord"
                placeholder={t('signup.form.securityWord')}
                value={formData.securityWord}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.securityWord && (
                <span className={styles.errorMessage}>
                  {errors.securityWord}
                </span>
              )}
            </div>
            <div className={styles.ctaForm}>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="isOver18"
                  checked={formData.isOver18}
                  onChange={handleChange}
                  className={styles.checkbox}
                />
                <span>{t('signup.form.ageConfirmation')}</span>
              </label>
              {errors.isOver18 && (
                <span className={styles.errorMessage}>{errors.isOver18}</span>
              )}
            </div>
            <button
              type="submit"
              className={styles.loginButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className={styles.loadingSpinner}>
                  {t('common.loading')}
                </span>
              ) : (
                t('signup.form.submitButton')
              )}
            </button>
          </form>
          {formData.errorMessage && (
            <p className={styles.errorMessage}>{formData.errorMessage}</p>
          )}
          <p>
            {t('signup.form.alreadyHaveAccount')}{' '}
            <Link to="/login">{t('signup.form.login')}</Link>
          </p>
        </div>
        <div className={styles.ctaImage}>
          <img className={styles.image} src={liga} alt="Celebrating team" />
        </div>
      </section>
    </div>
  );
};

export default SignupPage;
