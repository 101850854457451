import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  betOdd,
  betName,
  betSigne,
  betSigneSelected,
  betHandicap,
  betType,
  setBetKey,
  betMatch,
  addSelectedBet,
} from '../redux/betSlice';
import {useTranslation} from 'react-i18next';
import styles from './matchDetailsTennis.module.css';
import {Button} from 'react-bootstrap';

const MatchDetailsTennis = ({
  matchDetails,
  isModalOpen,
  setIsModalOpen,
  showMatchDetails,
  setShowMatchDetails,
  isMobile,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {betTeamHome, betTeamAway} = useSelector(state => state.bet);

  if (!matchDetails?.main_odds) {
    return null;
  }

  const renderModalBet = (
    odds,
    name,
    handicap,
    header,
    formattedKey,
    subkey,
    betKey
  ) => {
    header =
      header === '1' ? betTeamHome : header === '2' ? betTeamAway : header;

    // First dispatch all bet details
    dispatch(betMatch(`${betTeamHome} - ${betTeamAway}`));
    dispatch(betOdd(odds));
    dispatch(betName(formattedKey));
    dispatch(betHandicap(handicap));
    dispatch(betSigne(name));
    dispatch(betSigneSelected(header));
    dispatch(betType(subkey));
    dispatch(setBetKey(betKey));

    // Automatically add the bet
    dispatch(addSelectedBet());

    setIsModalOpen(true);
  };

  const renderBetOption = (bet, sectionName, betKey) => (
    <div
      key={bet.id}
      className={styles.matchOddsContainer}
      onClick={() =>
        renderModalBet(
          bet.odds,
          bet.name,
          bet.handicap,
          bet.header,
          sectionName,
          sectionName,
          betKey
        )
      }
    >
      <p className={styles.odds}>{bet.odds}</p>
      {bet.name && (
        <p className={styles.betName}>
          {bet.name === '1'
            ? betTeamHome
            : bet.name === '2'
              ? betTeamAway
              : bet.name}
        </p>
      )}
      {bet.handicap && <p className={styles.handicap}>{bet.handicap}</p>}
      {bet.header && (
        <p className={styles.betHeader}>
          {bet.header === 'teamhome'
            ? betTeamHome
            : bet.header === 'teamaway'
              ? betTeamAway
              : bet.header}
        </p>
      )}
    </div>
  );

  const renderSection = (title, bets, key) => {
    if (!bets || bets.length === 0) return null;

    return (
      <div className={styles.betGroup}>
        <h5 className={styles.betSubtypeTitle}>{title}</h5>
        <div className={styles.betRow}>
          {bets.map(bet => renderBetOption(bet, title, key))}
        </div>
      </div>
    );
  };

  const handleBackToList = () => {
    setShowMatchDetails(false);
  };

  const mainOddsKeys = {
    to_win_match: 'Match Winner',
    match_handicap_games: 'Match Handicap (Games)',
    total_games_2_way: 'Total Games',
    set_betting: 'Set Betting',
    first_set_winner: 'First Set Winner',
    first_set_total_games: 'First Set Total Games',
    first_set_score: 'First Set Score',
    match_result_and_total_games: 'Match Result & Total Games',
    total_sets: 'Total Sets',
    player_to_win_at_least_one_set: 'Player To Win At Least One Set',
    correct_score_in_sets: 'Correct Score In Sets',
    any_set_to_go_to_tiebreak: 'Any Set To Go To Tiebreak',
    match_to_go_to_final_set: 'Match To Go To Final Set',
    player_to_win_first_set_and_match: 'Player To Win First Set And Match',
    player_to_win_first_game: 'Player To Win First Game',
    first_set_race: 'First Set Race',
    match_total_tiebreaks: 'Match Total Tiebreaks',
  };

  return (
    <div
      className={`${styles.matchDetails} col-12 col-md-12 row ${isModalOpen ? styles.blur : ''}`}
      style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: '84vh'}}
    >
      {isMobile && (
        <Button onClick={handleBackToList} className={styles.backToListButton}>
          {t('matchDetails.backToList')}
        </Button>
      )}
      <div className={styles.matchDetailsContainer}>
        {Object.entries(mainOddsKeys).map(([key, title]) =>
          renderSection(title, matchDetails.main_odds[key], key)
        )}
      </div>
    </div>
  );
};

MatchDetailsTennis.propTypes = {
  matchDetails: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  showMatchDetails: PropTypes.bool.isRequired,
  setShowMatchDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MatchDetailsTennis;
