import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {
  setCoinsInLeague,
  removeSelectedBet,
  clearSelectedBets,
  setIsCombinationBet,
} from '../redux/betSlice';
import {useTranslation} from 'react-i18next';
import styles from './modalBet.module.css';
import Joyride, {STATUS} from 'react-joyride';
import PropTypes from 'prop-types';
import {
  incrementOnboardingStep,
  resetOnboarding,
  setIsNewUser,
} from '../redux/userSlice';

const ModalBet = ({isOpen, isNewUser, setIsModalOpen}) => {
  const {t} = useTranslation();
  const [betAmount, setBetAmount] = useState(1);
  const [error, setError] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;

  const {gameType, gameId, gameName} = useSelector(state => state.actualGame);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);
  const [betAmounts, setBetAmounts] = useState({});
  const {
    /* betMatch,
    betTeamHome,
    betTeamAway,
    betHandicap,
    betSigne,
    betType,
    betSigneSelected,
    betOdd,
    betName,
    betKey,
    matchId,
    matchTime, */
    betSport,
    coinsInLeague,
    selectedBets,
    isCombinationBet,
  } = useSelector(state => state.bet);

  const isLeague = gameType === 'leagues';

  useEffect(() => {
    if (isOpen && isNewUser) {
      setRunTour(true);
    }
  }, [isOpen, isNewUser]);

  useEffect(() => {
    if (selectedBets && selectedBets.length === 0 && !isNewUser) {
      setIsModalOpen(false);
    }
  }, [selectedBets, isNewUser]);

  const steps = [
    {
      target: '#modal-bet-overview',
      content: t('modalBet.onboarding.overview'),
      placement: 'center',
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      target: '#bet-type-toggle',
      content: t('modalBet.onboarding.betTypes'),
      disableBeacon: true,
    },
    {
      target: '#bet-amount-input',
      content: t('modalBet.onboarding.betAmount'),
      disableBeacon: true,
    },
    {
      target: '#potential-winnings',
      content: t('modalBet.onboarding.potentialWinnings'),
      disableBeacon: true,
    },
    {
      target: '#place-bet-button',
      content: t('modalBet.onboarding.placeBet'),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      dispatch(setIsNewUser(false));
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  const handleBetAmountChange = e => {
    const newBetAmount = e.target.value;
    setBetAmount(newBetAmount);
  };

  const handleIndividualBetAmountChange = (index, value) => {
    setBetAmounts(prev => ({
      ...prev,
      [index]: value,
    }));
  };

  const {userName, userId} = useSelector(state => state.user);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const calculateTotalOdds = () => {
    if (
      !selectedBets ||
      !Array.isArray(selectedBets) ||
      selectedBets.length === 0
    ) {
      return 0;
    }
    return selectedBets
      .filter(bet => !bet.isRepeated)
      .reduce((total, bet) => total * bet.betOdd, 1);
  };

  const renderSelectedCombiBets = () => {
    if (!selectedBets || !Array.isArray(selectedBets)) {
      return null;
    }

    const isBetRepeated = selectedBets.some(bet => bet.isRepeated === true);

    return (
      <>
        {selectedBets
          .filter(bet => !bet.isRepeated)
          .map((bet, index) => (
            <div key={index} className={styles.selectedBet}>
              <div className={styles.betInfo}>
                <p>{bet.betMatch}</p>
                <p>
                  {bet.betType}:{' '}
                  {bet.betSigne === '1'
                    ? bet.betTeamHome
                    : bet.betSigne === '2'
                      ? bet.betTeamAway
                      : bet.betSigne}{' '}
                  {bet.betSigneSelected} {bet.betHandicap} ({bet.betOdd})
                </p>
              </div>
              <button
                onClick={() => dispatch(removeSelectedBet(index))}
                className={styles.removeBet}
              >
                ✕
              </button>
            </div>
          ))}
        {isBetRepeated && (
          <div className={styles.duplicateWarning}>
            {t(
              'modalBet.duplicateMatchWarning',
              "Some bets are not showing because it's not possible to combine different bets from the same match"
            )}
          </div>
        )}
      </>
    );
  };

  const handleSubmitBet = async e => {
    e.preventDefault();

    if (isCombinationBet) {
      if (!selectedBets || selectedBets.length < 2) {
        setError(t('modalBet.minimumBetsError'));
        return;
      }

      if (gameType === 'leagues' && Number(betAmount) > coinsInLeague) {
        setError(t('modalBet.insufficientCoins'));
        return;
      }

      const totalOdd = calculateTotalOdds();

      const uniqueMatchBets = selectedBets.reduce((acc, bet) => {
        if (!acc.some(existingBet => existingBet.matchId === bet.matchId)) {
          acc.push(bet);
        }
        return acc;
      }, []);

      const betInfo = {
        bets: uniqueMatchBets,
        betAmount,
        coinsToWin: (betAmount * totalOdd).toFixed(2),
        userId,
        userName,
        leagueId: gameId,
        gameMode: gameType,
        isCombination: true,
        totalOdd: totalOdd.toFixed(2),
      };

      try {
        await axios.post(`${API_URL}/place-combi-bet`, betInfo);
        const updatedCoinsInLeague = coinsInLeague - betAmount;
        dispatch(setCoinsInLeague(updatedCoinsInLeague));
        dispatch(setIsNewUser(false));
        dispatch(clearSelectedBets());
        history.push(`/home/${gameType}/${gameId}/${gameName}`);
      } catch (error) {
        setError(t('modalBet.errorPlacingBet'));
      }
    } else {
      try {
        if (gameType === 'doubles') {
          if (selectedBets.length > 1) {
            setError(t('modalBet.doublesOneBetError'));
            return;
          }

          const bet = selectedBets[0];
          const betInfo = {
            betName: bet.betName,
            betKey: bet.betKey,
            betMatch: bet.betMatch,
            betType: bet.betType,
            betTeamHome: bet.betTeamHome,
            betTeamAway: bet.betTeamAway,
            betAmount: betAmounts[0] || 1,
            betHandicap: bet.betHandicap || 0,
            coinsToWin: (betAmounts[0] || 1) * bet.betOdd,
            betSigne: bet.betSigne,
            betSigneSelected: bet.betSigneSelected,
            betSport,
            leagueId: gameId,
            matchId: bet.matchId,
            matchTime: bet.matchTime,
            userId,
            userName,
            betOdd: bet.betOdd,
            gameMode: gameType,
          };

          await axios.post(`${API_URL}/place-bet`, betInfo);
          const updatedCoinsInLeague = coinsInLeague - (betAmounts[0] || 1);
          dispatch(setCoinsInLeague(updatedCoinsInLeague));
          dispatch(setIsNewUser(false));
          dispatch(clearSelectedBets());
          history.push(`/home/${gameType}/${gameId}/${gameName}`);
        } else {
          const promises = selectedBets.map((bet, index) => {
            const betInfo = {
              betName: bet.betName,
              betKey: bet.betKey,
              betMatch: bet.betMatch,
              betType: bet.betType,
              betTeamHome: bet.betTeamHome,
              betTeamAway: bet.betTeamAway,
              betAmount: betAmounts[index] || 1,
              betHandicap: bet.betHandicap || 0,
              coinsToWin: (betAmounts[index] || 1) * bet.betOdd,
              betSigne: bet.betSigne,
              betSigneSelected: bet.betSigneSelected,
              betSport,
              leagueId: gameId,
              matchId: bet.matchId,
              matchTime: bet.matchTime,
              userId,
              userName,
              betOdd: bet.betOdd,
              gameMode: gameType,
            };
            return axios.post(`${API_URL}/place-bet`, betInfo);
          });

          await Promise.all(promises);
          const totalBetAmount = Object.values(betAmounts).reduce(
            (sum, amount) => sum + Number(amount),
            0
          );
          const updatedCoinsInLeague = coinsInLeague - totalBetAmount;
          dispatch(setCoinsInLeague(updatedCoinsInLeague));
          dispatch(setIsNewUser(false));
          dispatch(clearSelectedBets());
          history.push(`/home/${gameType}/${gameId}/${gameName}`);
        }
      } catch (error) {
        setError(t('modalBet.errorPlacingBet'));
      }
    }
  };

  return (
    <div className={styles.container} id="modal-bet-overview">
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        stepIndex={onboardingStep}
        callback={handleJoyrideCallback}
        hideBackButton={true}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#007bff',
          },
          tooltip: {
            fontSize: '14px',
            padding: '15px',
          },
          buttonNext: {
            backgroundColor: '#007bff',
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
      />
      <div className={styles.betTypeToggle} id="bet-type-toggle">
        <button
          className={!isCombinationBet ? styles.active : ''}
          onClick={() => dispatch(setIsCombinationBet(false))}
        >
          {t('modalBet.singleBet')}
        </button>
        <button
          className={isCombinationBet ? styles.active : ''}
          onClick={() => dispatch(setIsCombinationBet(true))}
        >
          {t('modalBet.combinationBet')}
        </button>
      </div>

      <div
        className={
          isCombinationBet
            ? styles.combinationBetContainer
            : styles.singleBetsContainer
        }
      >
        {isCombinationBet ? (
          <>
            {renderSelectedCombiBets()}
            {selectedBets.length > 0 && (
              <div className={styles.betWrapper}>
                {gameType === 'leagues' ? (
                  <div className={styles.betAmountInput} id="bet-amount-input">
                    <p>{t('modalBet.amount')}</p>
                    <input
                      type="number"
                      name="betAmount"
                      value={betAmount}
                      onChange={handleBetAmountChange}
                      min="0"
                      placeholder={t('modalBet.amount')}
                    />
                  </div>
                ) : null}
                <div className={styles.betButton} id="potential-winnings">
                  {gameType === 'leagues' && (
                    <>
                      {t('modalBet.potentialWinnings')}{' '}
                      {(Number(betAmount) * calculateTotalOdds()).toFixed(2)}
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {selectedBets?.map((bet, index) => (
              <div key={index} className={styles.singleBetItem}>
                <div className={styles.betInfo}>
                  <p>{bet.betType}</p>
                  <p>{bet.betMatch}</p>
                  <h4>
                    {bet.betSigne === '1'
                      ? bet.betTeamHome
                      : bet.betSigne === '2'
                        ? bet.betTeamAway
                        : bet.betSigne}{' '}
                    {bet.betSigneSelected === 'teamhome'
                      ? bet.betTeamHome
                      : bet.betSigneSelected === 'teamaway'
                        ? bet.betTeamAway
                        : bet.betSigneSelected}{' '}
                    {bet.betHandicap} ({bet.betOdd})
                  </h4>
                </div>
                {isLeague && (
                  <div className={styles.betWrapper}>
                    <div
                      className={styles.betAmountInput}
                      id={index === 0 ? 'bet-amount-input' : undefined}
                    >
                      <p>{t('modalBet.amount')}</p>
                      <input
                        type="number"
                        name={`betAmount-${index}`}
                        value={betAmounts[index] || 1}
                        onChange={e =>
                          handleIndividualBetAmountChange(index, e.target.value)
                        }
                        min="0"
                        placeholder={t('modalBet.amount')}
                      />
                    </div>
                    <div
                      className={styles.betButton}
                      id={index === 0 ? 'potential-winnings' : undefined}
                    >
                      {t('modalBet.potentialWinnings')}{' '}
                      {((betAmounts[index] || 1) * bet.betOdd).toFixed(2)}
                    </div>
                  </div>
                )}
                <button
                  onClick={() => dispatch(removeSelectedBet(index))}
                  className={styles.removeBet}
                >
                  ✕
                </button>
              </div>
            ))}
          </>
        )}
      </div>

      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.betButtonWrapper}>
        <button
          onClick={handleSubmitBet}
          className={styles.betButtonFull}
          id="place-bet-button"
        >
          {t('modalBet.bet')}
        </button>
      </div>
    </div>
  );
};

ModalBet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default ModalBet;
