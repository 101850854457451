import ReactGA from 'react-ga4';

export const initializeGA = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING;
  if (trackingId) {
    ReactGA.initialize(trackingId);
    console.log('Google Analytics 4 initialized');
  } else {
    console.warn('Google Analytics Tracking ID not provided');
  }
};

export const logPageView = page => {
  ReactGA.send({hitType: 'pageview', page: page || window.location.pathname});
};
