import {useState, useEffect} from 'react';
import axios from 'axios';
import styles from './messages.module.css';
import Navigation from '../../components/Navbar';

const API_URL = process.env.REACT_APP_API_URL;

const Message = () => {
  const [messages, setMessages] = useState([]);
  const [filter, setFilter] = useState('all'); // 'all', 'answered', 'pending'

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleStatusChange = async (id, currentStatus) => {
    try {
      await axios.put(`${API_URL}/auth/messages/${id}`, {
        answered: !currentStatus,
      });
      fetchMessages(); // Refresh messages after update
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };

  const filteredMessages = messages.filter(message => {
    if (filter === 'all') return true;
    if (filter === 'answered') return message.answered;
    if (filter === 'pending') return !message.answered;
    return true;
  });

  const formatDate = dateString => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <div className={styles.container}>
      <Navigation />
      <div className={styles.content}>
        <h1 className={styles.title}>Messages</h1>

        <div className={styles.filterContainer}>
          <button
            className={`${styles.filterButton} ${filter === 'all' ? styles.active : ''}`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button
            className={`${styles.filterButton} ${filter === 'pending' ? styles.active : ''}`}
            onClick={() => setFilter('pending')}
          >
            Pending
          </button>
          <button
            className={`${styles.filterButton} ${filter === 'answered' ? styles.active : ''}`}
            onClick={() => setFilter('answered')}
          >
            Answered
          </button>
        </div>

        <div className={styles.messagesGrid}>
          {filteredMessages.map(message => (
            <div
              key={message._id}
              className={`${styles.messageCard} ${message.answered ? styles.answered : styles.pending}`}
            >
              <div className={styles.messageHeader}>
                <h3>{message.name}</h3>
                <span className={styles.date}>
                  {formatDate(message.createdAt)}
                </span>
              </div>
              <div className={styles.messageBody}>
                <p className={styles.email}>{message.email}</p>
                <p className={styles.messageText}>{message.message}</p>
              </div>
              <div className={styles.messageFooter}>
                <button
                  className={`${styles.statusButton} ${message.answered ? styles.answeredButton : styles.pendingButton}`}
                  onClick={() =>
                    handleStatusChange(message._id, message.answered)
                  }
                >
                  {message.answered ? 'Mark as Pending' : 'Mark as Answered'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Message;
