import {useTranslation} from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import styles from './qa.module.css';
import {Link} from 'react-router-dom';

const Qa = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.qaContainer}>
      <div className={styles.languageSwitcherContainer}>
        <Link to="/home">
          <button className={styles.homeButton}>{t('qa.common.home')}</button>
        </Link>
        <LanguageSwitcher />
      </div>

      <div className={styles.content}>
        <h1>{t('qa.title')}</h1>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.account.title')}</h2>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.account.create.question')}</h3>
            <ul>
              {t('qa.sections.account.create.requirements', {
                returnObjects: true,
              }).map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.account.multiple.question')}</h3>
            <p>{t('qa.sections.account.multiple.answer')}</p>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.account.realname.question')}</h3>
            <p>{t('qa.sections.account.realname.answer')}</p>
          </div>
        </section>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.platform.title')}</h2>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.platform.languages.question')}</h3>
            <ul>
              {t('qa.sections.platform.languages.list', {
                returnObjects: true,
              }).map((lang, index) => (
                <li key={index}>{lang}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.platform.navigation.question')}</h3>
            <ul>
              {t('qa.sections.platform.navigation.sections', {
                returnObjects: true,
              }).map((section, index) => (
                <li key={index}>{section}</li>
              ))}
            </ul>
          </div>
        </section>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.gameplay.title')}</h2>
          <h3>{t('qa.sections.gameplay.leagues.subtitle')}</h3>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.gameplay.leagues.how.question')}</h3>
            <ul>
              {t('qa.sections.gameplay.leagues.how.points', {
                returnObjects: true,
              }).map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.gameplay.leagues.options.question')}</h3>
            <ul>
              {t('qa.sections.gameplay.leagues.options.list', {
                returnObjects: true,
              }).map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.gameplay.leagues.distribution.question')}</h3>
            <ul>
              {t('qa.sections.gameplay.leagues.distribution.options', {
                returnObjects: true,
              }).map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          </div>

          <h3>{t('qa.sections.gameplay.doubleOrNothing.subtitle')}</h3>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.gameplay.doubleOrNothing.how.question')}</h3>
            <ul>
              {t('qa.sections.gameplay.doubleOrNothing.how.rules', {
                returnObjects: true,
              }).map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>
              {t('qa.sections.gameplay.doubleOrNothing.customize.question')}
            </h3>
            <ul>
              {t('qa.sections.gameplay.doubleOrNothing.customize.options', {
                returnObjects: true,
              }).map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          </div>
        </section>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.betting.title')}</h2>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.betting.sports.question')}</h3>
            <ul>
              {t('qa.sections.betting.sports.list', {returnObjects: true}).map(
                (sport, index) => (
                  <li key={index}>{sport}</li>
                )
              )}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.betting.leagues.question')}</h3>
            <ul>
              {t('qa.sections.betting.leagues.list', {returnObjects: true}).map(
                (league, index) => (
                  <li key={index}>{league}</li>
                )
              )}
            </ul>
          </div>
        </section>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.payments.title')}</h2>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.payments.methods.question')}</h3>
            <ul>
              {t('qa.sections.payments.methods.list', {
                returnObjects: true,
              }).map((method, index) => (
                <li key={index}>{method}</li>
              ))}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.payments.platform.question')}</h3>
            <p>{t('qa.sections.payments.platform.answer')}</p>
          </div>
        </section>

        <section className={styles.qaSection}>
          <h2>{t('qa.sections.special.title')}</h2>
          <div className={styles.qaItem}>
            <h3>{t('qa.sections.special.tie.question')}</h3>
            <ul>
              {t('qa.sections.special.tie.rules', {returnObjects: true}).map(
                (rule, index) => (
                  <li key={index}>{rule}</li>
                )
              )}
            </ul>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.special.resolution.question')}</h3>
            <p>{t('qa.sections.special.resolution.answer')}</p>
          </div>

          <div className={styles.qaItem}>
            <h3>{t('qa.sections.special.newUsers.question')}</h3>
            <p>{t('qa.sections.special.newUsers.answer')}</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Qa;
