import {useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const API_URL = process.env.REACT_APP_API_URL;

const options = [
  {value: 'more-sports', label: 'More sports to bet'},
  {value: 'team-leagues', label: 'Team leagues'},
  {value: 'betting-forum', label: 'Betting forum'},
  {value: 'combinada', label: 'Combined bets'},
  {value: 'chat', label: 'Chat between friends'},
];

const LeagueQuiz = ({leagueId, userId, userName}) => {
  const [selectedOptions, setSelectedOptions] = useState(Array(4).fill(''));
  const [textArea, setTextArea] = useState('');

  const handleOptionChange = (index, value) => {
    setSelectedOptions(prevOptions => {
      const newOptions = [...prevOptions];
      newOptions[index] = value;
      return newOptions;
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const newQuiz = {
      option1: selectedOptions[0],
      option2: selectedOptions[1],
      option3: selectedOptions[2],
      option4: selectedOptions[3],
      playerOption: textArea,
      userId,
      leagueId,
      userName,
    };

    axios.post(`${API_URL}/new-quiz`, newQuiz).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="AddApartmentPage">
      <hr />
      <h3>What would you like Betandfriends to develop next?</h3>
      <p>30 seconds quiz and see the results</p>
      <form className="form-league-complete" onSubmit={handleSubmit}>
        {[...Array(4)].map((_, index) => (
          <div key={index}>
            <h6 className="h6QuizEndLeague">{`${index + 1}th choice to do`}</h6>
            <div className="formLeagueComplete">
              {options.map(({value, label}) => (
                <div key={value}>
                  <label>
                    <input
                      type="radio"
                      name={`option${index + 1}`}
                      value={value}
                      checked={selectedOptions[index] === value}
                      onChange={() => handleOptionChange(index, value)}
                    />
                    <span>{label}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
        <h6 className="h6QuizEndLeague">
          Write anything you would like betandfriends to have
        </h6>
        <textarea
          className="textAreaQuiz"
          name="playerOption"
          value={textArea}
          onChange={e => setTextArea(e.target.value)}
        ></textarea>
        <button className="sendQuiz-button" type="submit">
          SEND AND SEE LEAGUE RESULTS
        </button>
      </form>
    </div>
  );
};

LeagueQuiz.propTypes = {
  leagueId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default LeagueQuiz;
