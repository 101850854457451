import {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AuthContext} from '../../context/auth.context';
import {setUserMoney} from '../../redux/userSlice';
import axios from 'axios';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useTranslation} from 'react-i18next';
import styles from './withdrawalPage.module.css';

function WithdrawalPage() {
  const API_URL = process.env.REACT_APP_API_URL;
  const {t} = useTranslation();
  const {setUser} = useContext(AuthContext);
  const dispatch = useDispatch();
  const {userMoney, userId, userName} = useSelector(state => state.user);
  const [eurosWithdrawal, setEurosWithdrawal] = useState('');
  const [ibanBankAccount, setIbanBankAccount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (Number(eurosWithdrawal) < 10) {
      setError(t('withdrawalPage.minWithdrawalWarning'));
      return;
    }

    if (!eurosWithdrawal || !ibanBankAccount) {
      setError(t('withdrawalPage.fillAllFields'));
      return;
    }

    if (ibanBankAccount.length !== 24) {
      setError(t('withdrawalPage.errorIBAN'));
      return;
    }

    const withdrawalAmount = Number(eurosWithdrawal);

    if (withdrawalAmount > userMoney) {
      setError(t('withdrawalPage.insufficientFundsWarning'));
      return;
    }

    try {
      const storedToken = localStorage.getItem('authToken');
      const response = await axios.post(
        `${API_URL}/withdraw`,
        {
          amount: withdrawalAmount,
          userId,
          userName,
          bankAccount: ibanBankAccount,
        },
        {
          headers: {Authorization: `Bearer ${storedToken}`},
        }
      );

      const newUserMoney = userMoney - withdrawalAmount;
      dispatch(setUserMoney(newUserMoney));
      setUser(prevUser => ({...prevUser, coins: response.data.newCoins}));
      setSuccess(t('withdrawalPage.successMessage'));
      setTimeout(() => history.push('/home'), 2000);
    } catch (error) {
      setError(
        error.response?.data?.message || t('withdrawalPage.errorMessage')
      );
    }
  };

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.withdrawalContainer}>
        <div className={styles.switchButtons}>
          <button
            className={styles.inactiveButton}
            onClick={() => history.push('/home/deposit')}
          >
            {t('depositPage.deposit')}
          </button>
          <button
            className={styles.activeButton}
            onClick={() => history.push('/home/withdraw')}
          >
            {t('withdrawalPage.withdraw')}
          </button>
        </div>
        <h2 className={styles.title}>{t('withdrawalPage.title')}</h2>
        <p className={styles.infoText}>
          {t('withdrawalPage.availableFunds')}: {userMoney.toFixed(2)}€
        </p>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label htmlFor="withdrawal-amount">
              {t('withdrawalPage.amount')}
            </label>
            <input
              id="withdrawal-amount"
              type="number"
              value={eurosWithdrawal}
              onChange={e => setEurosWithdrawal(e.target.value)}
              min="10"
              max={userMoney}
              required
              className={styles.input}
              placeholder="Min: 10€"
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="iban-account">IBAN</label>
            <input
              id="iban-account"
              type="text"
              value={ibanBankAccount}
              onChange={e => setIbanBankAccount(e.target.value)}
              required
              className={styles.input}
              placeholder="ES91 2100 0418 4502 0005 1332"
            />
          </div>

          <button type="submit" className={styles.btn}>
            {t('withdrawalPage.withdrawButton')}
          </button>
        </form>

        {error && (
          <div className={`${styles.message} ${styles.error}`}>{error}</div>
        )}
        {success && (
          <div className={`${styles.message} ${styles.success}`}>{success}</div>
        )}
      </div>
    </>
  );
}

export default WithdrawalPage;
