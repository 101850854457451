import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  gameType: '',
  gameName: '',
  gameId: '',
  error: false,
  canBet: true,
};

export const actualGameSlice = createSlice({
  name: 'actualGame',
  initialState,
  reducers: {
    leaguesStart: state => {
      state.loading = true;
    },
    setGameName: (state, action) => {
      state.gameName = action.payload;
    },
    setGameId: (state, action) => {
      state.gameId = action.payload;
    },
    setGameType: (state, action) => {
      state.gameType = action.payload;
    },
    setCanBet: (state, action) => {
      state.canBet = action.payload;
    },
    updateLeague: state => {
      state.loading = false;
      state.error = true;
    },
    subscription: (state, action) => {
      if (state.currentUser.subscribedUsers.includes(action.payload)) {
        state.currentUser.subscribedUsers.splice(
          state.currentUser.subscribedUsers.findIndex(
            channelId => channelId === action.payload
          ),
          1
        );
      } else {
        state.currentUser.subscribedUsers.push(action.payload);
      }
    },
  },
});

export const {
  leaguesStart,
  setGameName,
  setGameId,
  setGameType,
  subscription,
  setCanBet,
} = actualGameSlice.actions;

export default actualGameSlice.reducer;
