import {useState, useContext, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {AuthContext} from '../../context/auth.context';
import {useUserInLeagueCoins} from '../../hooks/useUserInLeagueCoins';
import {setCoinsInLeague, betSport} from '../../redux/betSlice';
import SubNavbar from '../../components/SubNavbar';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import league1 from './assets/league1.jpeg';
import laliga from './assets/laliga.jpeg';
import seriea from './assets/calcio.jpeg';
import premier from './assets/premier.jpeg';
import bundesleague from './assets/bundesliga.jpeg';
import acb from './assets/acb.png';
import euroliga from './assets/euroliga.png';
import legaitaliana from './assets/legaitalia.png';
import nba from './assets/nba.png';
import ligaalemana from './assets/ligalemana.png';
import balonFutbol from './assets/soccerBall.png';
import balonBasquet from './assets/basketBall.png';
import balonTennis from './assets/tennisBall.png';
import {useTranslation} from 'react-i18next';
import styles from './competitionsBet.module.css';
import Joyride, {STATUS} from 'react-joyride';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionsPage = () => {
  const {t} = useTranslation();
  const [selectedSport, setSelectedSport] = useState(null);
  const [showMoreLeagues, setShowMoreLeagues] = useState(false);
  const {isLoggedIn} = useContext(AuthContext);
  const dispatch = useDispatch();
  const {gameId, gameType, gameName} = useSelector(state => state.actualGame);
  const {userId} = useSelector(state => state.user);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const [runTour, setRunTour] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [competitions, setCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [fetchError, setFetchError] = useState(null);

  const {userInLeagueCoins} = useUserInLeagueCoins(userId, gameId, isLoggedIn);

  useEffect(() => {
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser]);

  const steps = useMemo(() => {
    const baseSteps = [
      {
        target: '#betFutbol',
        content: t('competitionsBet.onboarding.clickFootball'),
        disableBeacon: true,
      },
    ];

    if (selectedSport === 'futbol') {
      baseSteps.push({
        target: 'img[alt="seriaA"]',
        content: t('competitionsBet.onboarding.clickSerieA'),
      });
    }

    return baseSteps;
  }, [selectedSport, t]);

  useEffect(() => {
    dispatch(
      setCoinsInLeague(Number(userInLeagueCoins?.coinsInLeague).toFixed(2))
    );
  }, [userInLeagueCoins]);

  const handleJoyrideCallback = data => {
    const {status, type, index} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    } else if (type === 'step:after') {
      setCurrentStep(index + 1);
    }
  };

  const handleSportSelection = async sport => {
    dispatch(betSport(sport));
    setSelectedSport(sport);
    setShowMoreLeagues(false);

    // Only fetch for basketball and tennis
    if (sport !== 'futbol') {
      // Map sport names to sport IDs
      const sportIds = {
        basquet: '18',
        tennis: '13',
      };

      const sportId = sportIds[sport];

      if (sportId) {
        setIsLoading(true);
        //  setFetchError(null);

        try {
          const response = await axios.get(`${API_URL}/sport/${sportId}`);

          if (response.data.success) {
            setCompetitions(response.data.data);
          } else {
            throw new Error('Failed to fetch competitions');
          }
        } catch (error) {
          console.error('Error fetching competitions:', error);
          //    setFetchError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    }

    if (sport === 'futbol') {
      setCurrentStep(1);
      isNewUser && setRunTour(true);
    }
  };

  const soccerCompetitions = [
    {id: 10041100, label: 'league1', image: league1, sport: 1},
    {id: 10083364, label: 'laLiga', image: laliga, sport: 1},
    {id: 10041315, label: 'seriaA', image: seriea, sport: 1},
    {id: 10041282, label: 'premier-league', image: premier, sport: 1},
    {id: 10041095, label: 'bundensliga', image: bundesleague, sport: 1},
  ];

  const basketballCompetitions = [
    {id: 10036607, label: 'league1', image: acb, sport: 18},
    {id: 10041830, label: 'laLiga', image: nba, sport: 18},
    {id: 10040795, label: 'seriaA', image: euroliga, sport: 18},
    {id: 10037165, label: 'premier-league', image: ligaalemana, sport: 18},
    {id: 10036608, label: 'bundensliga', image: legaitaliana, sport: 18},
  ];

  const soccerMoreLeagues = [
    {id: 10041809, label: 'championsLeague', sport: 1},
    {id: 10040471, label: 'brazilianLeague', sport: 1},
    {id: 10041957, label: 'mexicanLeague', sport: 1},
    {id: 10036538, label: 'spainSegundaDivision', sport: 1},
    {id: 10041101, label: 'englandChampionship', sport: 1},
    {id: 10041109, label: 'italySerieB', sport: 1},
    {id: 10041391, label: 'netherlandsEredivisie', sport: 1},
    {id: 10042035, label: 'portugalPrimeiraLiga', sport: 1},
    {id: 10041396, label: 'greeceSuperLeague', sport: 1},
    {id: 10041096, label: 'belgiumFirstDivision', sport: 1},
    {id: 10041077, label: 'turkeySuperLig', sport: 1},
    {id: 10041058, label: 'denmarkSuperligaen', sport: 1},
    {id: 10041006, label: 'bulgariaFirstLeague', sport: 1},
    {id: 10041039, label: 'serbiaSuperLiga', sport: 1},
    {id: 10070454, label: 'argentinaLigaProfesional', sport: 1},
    {id: 10037440, label: 'brazilSerieA', sport: 1},
    {id: 10037445, label: 'uruguayClausura', sport: 1},
    {id: 10040906, label: 'japanJ-League', sport: 1},
  ];

  const toggleMoreLeagues = () => {
    setShowMoreLeagues(prevState => !prevState);
  };

  const renderCompetitions = competitions => (
    <div className={styles.competitionsContainer}>
      {competitions.map(({id, image, label, sport}) => (
        <Link
          key={id}
          to={`/home/${gameType}/${gameId}/${gameName}/competitions/${id}/${sport}/bet`}
          className={styles.competitionCard}
        >
          <img className={styles.competitionImage} src={image} alt={label} />
        </Link>
      ))}
    </div>
  );

  const renderMoreLeagues = () => (
    <div className={styles.moreLeaguesGrid}>
      {soccerMoreLeagues.map(({id, label, sport}) => (
        <Link
          key={id}
          to={`/home/${gameType}/${gameId}/${gameName}/competitions/${id}/${sport}/bet`}
          className={styles.leagueButton}
        >
          {t(`competitions.leagues.${label}`)}
        </Link>
      ))}
    </div>
  );

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <SubNavbar id={gameId} game={gameType} leagueName={gameName} />
      {isLoggedIn && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          stepIndex={currentStep}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              primaryColor: '#007bff',
            },
          }}
          debug={true}
        />
      )}
      <div className={styles.pageContainer}>
        <div className={styles.sportsContainer}>
          <button
            id="betFutbol"
            className={`${styles.sportButton} ${
              selectedSport === 'futbol' ? styles.active : ''
            }`}
            onClick={() => handleSportSelection('futbol')}
          >
            <img
              className={styles.sportIcon}
              src={balonFutbol}
              alt={t('competitionsBet.soccer')}
            />
          </button>
          <button
            className={`${styles.sportButton} ${
              selectedSport === 'basquet' ? styles.active : ''
            }`}
            onClick={() => handleSportSelection('basquet')}
          >
            <img
              className={styles.sportIcon}
              src={balonBasquet}
              alt={t('competitionsBet.basketball')}
            />
          </button>
          <button
            className={`${styles.sportButton} ${
              selectedSport === 'tennis' ? styles.active : ''
            }`}
            onClick={() => handleSportSelection('tennis')}
          >
            <img
              className={styles.sportIcon}
              src={balonTennis}
              alt={t('competitionsBet.basketball')}
            />
          </button>
        </div>
        {isLoading && (
          <div className={styles.loading}>Loading competitions...</div>
        )}
        {selectedSport === 'futbol' && (
          <>
            {renderCompetitions(soccerCompetitions)}
            <div className={styles.moreLeaguesSection}>
              <button
                className={styles.toggleButton}
                onClick={toggleMoreLeagues}
              >
                {t('competitionsBet.moreLeagues')}
              </button>
              {showMoreLeagues && renderMoreLeagues()}
            </div>
            <div className={styles.quickLinksSection}>
              <Link
                to={`/home/${gameType}/${gameId}/${gameName}/competitions/0/1/bet`}
                className={styles.quickLink}
              >
                {t('competitionsBet.inAHurry')}?{' '}
                <span>{t('competitionsBet.nextMatches')}</span>
              </Link>
            </div>
          </>
        )}

        {selectedSport === 'basquet' && (
          <>
            {renderCompetitions(basketballCompetitions)}
            <div className={styles.moreLeaguesSection}>
              <button
                className={styles.toggleButton}
                onClick={toggleMoreLeagues}
              >
                {t('competitionsBet.moreLeagues')}
              </button>
              {showMoreLeagues && (
                <div className={styles.moreLeaguesGrid}>
                  {competitions.map(competition => (
                    <Link
                      key={competition.leagueId}
                      to={`/home/${gameType}/${gameId}/${gameName}/competitions/${competition.leagueId}/${competition.sport}/bet`}
                      className={styles.leagueButton}
                    >
                      {competition.leagueName}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.quickLinksSection}>
              <Link
                to={`/home/${gameType}/${gameId}/${gameName}/competitions/0/18/bet`}
                className={styles.quickLink}
              >
                {t('competitionsBet.inAHurry')}?{' '}
                <span>{t('competitionsBet.nextMatches')}</span>
              </Link>
            </div>
          </>
        )}

        {selectedSport === 'tennis' && (
          <>
            <div className={styles.moreLeaguesGrid}>
              {competitions.map(competition => (
                <Link
                  key={competition.leagueId}
                  to={`/home/${gameType}/${gameId}/${gameName}/competitions/${competition.leagueId}/${competition.sport}/bet`}
                  className={styles.leagueButton}
                >
                  {competition.leagueName}
                </Link>
              ))}
            </div>

            <div className={styles.quickLinksSection}>
              <Link
                to={`/home/${gameType}/${gameId}/${gameName}/competitions/0/13/bet`}
                className={styles.quickLink}
              >
                {t('competitionsBet.inAHurry')}?{' '}
                <span>{t('competitionsBet.nextMatches')}</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CompetitionsPage;
