import {useState, useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styles from './AdminWithdrawals.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const AdminWithdrawals = () => {
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {token} = useSelector(state => state.user);

  useEffect(() => {
    const fetchPendingWithdrawals = async () => {
      try {
        const response = await axios.get(`${API_URL}/pending-withdrawals`, {
          headers: {Authorization: `Bearer ${token}`},
        });
        setPendingWithdrawals(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching pending withdrawals:', err);
        setError('Error fetching pending withdrawals');
        setLoading(false);
      }
    };

    fetchPendingWithdrawals();
  }, [token]);

  const handleApprove = async withdrawalId => {
    try {
      await axios.post(
        `${API_URL}/payment/approve-withdrawal/${withdrawalId}`,
        {},
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      setPendingWithdrawals(
        pendingWithdrawals.filter(w => w._id !== withdrawalId)
      );
    } catch (err) {
      console.error('Error approving withdrawal:', err);
      setError('Error approving withdrawal');
    }
  };

  const handleReject = async withdrawalId => {
    try {
      await axios.post(
        `${API_URL}/payment/reject-withdrawal/${withdrawalId}`,
        {},
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      setPendingWithdrawals(
        pendingWithdrawals.filter(w => w._id !== withdrawalId)
      );
    } catch (err) {
      console.error('Error rejecting withdrawal:', err);
      setError('Error rejecting withdrawal');
    }
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Pending Withdrawals</h1>
      {pendingWithdrawals.length === 0 ? (
        <p className={styles.noWithdrawals}>No pending withdrawals</p>
      ) : (
        <table className={styles.withdrawalsTable}>
          <thead>
            <tr>
              <th>User</th>
              <th>Amount</th>
              <th>Bank Account</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pendingWithdrawals.map(withdrawal => (
              <tr key={withdrawal._id}>
                <td>{withdrawal.userName}</td>
                <td>€{withdrawal.amount.toFixed(2)}</td>
                <td>{withdrawal.bankAccount}</td>
                <td>{new Date(withdrawal.createdAt).toLocaleString()}</td>
                <td>
                  <button
                    className={styles.approveButton}
                    onClick={() => handleApprove(withdrawal._id)}
                  >
                    Approve
                  </button>
                  <button
                    className={styles.rejectButton}
                    onClick={() => handleReject(withdrawal._id)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to="/admin" className={styles.backButton}>
        Back to Admin Home
      </Link>
    </div>
  );
};

export default AdminWithdrawals;
