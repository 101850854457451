import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openLeagues: [],
  loading: false,
  error: false,
  
};

const leagueSlice = createSlice({
  name: "leagues",
  initialState,
  reducers: {
    leaguesStart: (state) => ({ ...state, loading: true }),
    leaguesSuccess: (state, action) => ({
      ...state,
      loading: false,
      openLeagues: [...state.openLeagues, action.payload],
    }),
    leaguesOutToLogin: (state) => ({ ...state, openLeagues: [] }),
    leagueUpdate: (state, action) => ({
      ...state,
      openLeagues: [...state.openLeagues, action.payload],
    }),
    leaguesFailure: (state) => ({ ...state, loading: false, error: true }),
    updateLeague: (state) => ({ ...state, loading: false, error: true }),
    updateOpenLeagues: (state, action) => ({
      ...state,
      openLeagues: [...state.openLeagues, action.payload],
    }),
    subscription: (state, action) => {
      const { currentUser, payload } = action;
      const subscribedUsers = currentUser.subscribedUsers.includes(payload)
        ? currentUser.subscribedUsers.filter((channelId) => channelId !== payload)
        : [...currentUser.subscribedUsers, payload];

      return {
        ...state,
        currentUser: {
          ...currentUser,
          subscribedUsers,
        },
      };
    },
  },
});

export const {
  leaguesStart,
  leaguesFailure,
  leaguesSuccess,
  subscription,
  updateOpenLeagues,
  leaguesOutToLogin,
  updateLeague,
  leagueUpdate,
} = leagueSlice.actions;

export default leagueSlice.reducer;