import {useContext, useState} from 'react';
import Navigation from '../../components/Navbar';
import {AuthContext} from '../../context/auth.context';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import styles from './profilePage.module.css';

function ProfilePage() {
  const API_URL = process.env.REACT_APP_API_URL;
  const {t} = useTranslation();
  const {setUser} = useContext(AuthContext);
  const {userId, userMail, userName} = useSelector(state => state.user);
  const [email, setEmail] = useState(userMail);
  const [username, setUserName] = useState(userName);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const storedToken = localStorage.getItem('authToken');
      const response = await axios.put(
        `${API_URL}/auth/update-profile`,
        {
          userId,
          email,
          username,
          oldPassword,
          newPassword,
        },
        {
          headers: {Authorization: `Bearer ${storedToken}`},
        }
      );
      setUser(response.data.user);
      setMessage(t('profilePage.profileUpdated'));
      setOldPassword('');
      setNewPassword('');
      localStorage.setItem('authToken', response.data.authToken);
    } catch (error) {
      setMessage(
        error.response?.data?.message || t('profilePage.errorUpdating')
      );
      console.error(error);
    }
  };

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.profileContainer}>
        <h2 className={styles.title}>{t('profilePage.title')}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label htmlFor="email">{t('profilePage.email')}</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
              className={styles.input}
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="username">{t('profilePage.username')}</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={e => setUserName(e.target.value)}
              required
              className={styles.input}
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="oldPassword">
              {t('profilePage.currentPassword')}
            </label>
            <input
              id="oldPassword"
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              className={styles.input}
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="newPassword">{t('profilePage.newPassword')}</label>
            <input
              id="newPassword"
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className={styles.input}
            />
          </div>

          <button type="submit" className={styles.btn}>
            {t('profilePage.updateProfileButton')}
          </button>
        </form>

        {message && (
          <div
            className={`${styles.message} ${
              message.includes('Error') ? styles.error : styles.success
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </>
  );
}

export default ProfilePage;
