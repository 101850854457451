import './App.css';
import {Switch, Route, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {initializeGA, logPageView} from './analytics'; // Importamos los métodos de tracking
import Dashboard from './pages/Admin/Dashboard';
import HomePage from './pages/HomePage/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import Qa from './pages/Qa';
import DepositPage from './pages/Deposit/DepositPage';
import WithdrawalPage from './pages/Withdraw/WithdrawalPage';
import ProfilePage from './pages/Profile/ProfilePage';
import SignupPage from './pages/Signup/SignupPage';
import LoginPage from './pages/Login/LoginPage';
import AnonRoute from './components/AnonRoute';
import HomeLeagues from './pages/Leagues/HomeLeagues';
import CreateLeague from './pages/Leagues/CreateLeague';
import AllLeagues from './pages/Leagues/AllLeagues';
import MyLeagues from './pages/Leagues/MyLeagues';
import UserBets from './pages/Leagues/UserBets';
import SeeLeagues from './pages/Leagues/SeeLeagues';
import LeagueDetails from './pages/Leagues/LeagueDetails';
import CreateDouble from './pages/Doubles/CreateDouble';
import HomeDoubles from './pages/Doubles/HomeDoubles';
import AllDoubles from './pages/Doubles/AllDoubles';
import MyDoubles from './pages/Doubles/MyDoubles';
import DoubleDetails from './pages/Doubles/DoubleDetails';
import CompetitionsBet from './pages/Competitions/CompetitionsBet';
import PrivateRoute from './components/PrivateRoute';
import MatchesCompetitionsBet from './pages/Competitions/MatchesCompetitionsBet';
import MyBetsPage from './pages/BetPages/MyBetsPage';
import NotificationsPage from './pages/Notifications/NotificationsPage';
import EntryPage from './pages/EntryPage';
import ResetPassword from './pages/ResetPassword';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import AdminRoute from './components/AdminRoute';
import Promotions from './pages/Admin/Promotions';
import AdminHome from './pages/Admin/AdminHome';
import AdminWithdrawals from './pages/Admin/AdminWithdrawals';
import Messages from './pages/Admin/Messages';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initializeGA(); // Inicializar Google Analytics
  }, []);

  useEffect(() => {
    logPageView(); // Registrar vistas de página al cambiar de ruta
  }, [location]);

  return (
    <div className="App">
      <Switch>
        <PrivateRoute exact path="/home" component={HomePage} />
        <PrivateRoute exact path="/qa" component={Qa} />
        <AnonRoute exact path="/" component={EntryPage} />
        <PrivateRoute exact path="/home/leagues" component={HomeLeagues} />
        <PrivateRoute exact path="/home/doubles" component={HomeDoubles} />
        <PrivateRoute exact path="/home/about-us" component={AboutUsPage} />
        <PrivateRoute exact path="/signup" component={SignupPage} />
        <PrivateRoute path="/reset-password/:token" component={ResetPassword} />
        <PrivateRoute path="/recover-password" component={RecoverPassword} />
        <AnonRoute exact path="/login" component={LoginPage} />
        <PrivateRoute
          exact
          path="/notifications"
          component={NotificationsPage}
        />
        <PrivateRoute exact path="/home/deposit" component={DepositPage} />
        <PrivateRoute exact path="/home/withdraw" component={WithdrawalPage} />
        <PrivateRoute exact path="/home/profile" component={ProfilePage} />
        <PrivateRoute
          exact
          path="/home/leagues/create-league"
          component={CreateLeague}
        />
        <PrivateRoute
          exact
          path="/home/leagues/my-leagues"
          component={MyLeagues}
        />
        <PrivateRoute
          exact
          path="/home/leagues/all-leagues"
          component={AllLeagues}
        />
        <PrivateRoute
          exact
          path="/home/leagues/:id/:leagueName"
          component={LeagueDetails}
        />
        <PrivateRoute
          exact
          path="/home/leagues/all-leagues/:leagueName/:id"
          component={SeeLeagues}
        />
        <Route
          exact
          path="/home/leagues/:gameName/:leagueId/:userId/:userName"
          component={UserBets}
        />
        <PrivateRoute
          exact
          path="/home/:game/:id/:leagueName/competitions"
          component={CompetitionsBet}
        />
        <PrivateRoute
          exact
          path="/home/:game/:id/:leagueName/competitions/:compId/:sport/bet"
          component={MatchesCompetitionsBet}
        />
        <PrivateRoute
          exact
          path="/home/doubles/create-double"
          component={CreateDouble}
        />
        <PrivateRoute
          exact
          path="/home/doubles/my-doubles"
          component={MyDoubles}
        />
        <PrivateRoute
          exact
          path="/home/doubles/:id/:doubleName"
          component={DoubleDetails}
        />
        <PrivateRoute path="/home/doubles/all-doubles" component={AllDoubles} />
        <PrivateRoute
          path="/home/:game/:id/:leagueName/my-bets"
          component={MyBetsPage}
        />
        <AdminRoute
          path="/admin/pending-withdrawals"
          component={AdminWithdrawals}
        />
        <AdminRoute path="/admin/promotions" component={Promotions} />
        <AdminRoute path="/admin/dashboard" component={Dashboard} />
        <AdminRoute path="/admin/messages" component={Messages} />
        <AdminRoute path="/admin" component={AdminHome} />
      </Switch>
    </div>
  );
};

export default App;
