import {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useDispatch, useSelector} from 'react-redux';
import {setGameName, setGameId, setGameType} from '../../redux/actualGameSlice';
import styles from './myDoubles.module.css';
import {useTranslation} from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL;

const MyDoubles = () => {
  const {t} = useTranslation();
  const [myDoubles, setMyDoubles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const {userId} = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const getMyDoubles = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/my-doubles`, {
        userId,
      });
      setMyDoubles(response.data.openDoubles);
    } catch (error) {
      console.error('Error fetching doubles:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getMyDoubles();
  }, [getMyDoubles]);

  const handleSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({key, direction});
  };

  const getSortedDoubles = () => {
    if (!sortConfig.key) {
      return [...myDoubles].sort((a, b) => {
        if (a.condition === 'open' && b.condition !== 'open') return -1;
        if (a.condition !== 'open' && b.condition === 'open') return 1;

        return new Date(a.finishDate * 1000) - new Date(b.finishDate * 1000);
      });
    }

    return [...myDoubles].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'participants') {
        aValue = a.participants.length;
        bValue = b.participants.length;
      } else if (sortConfig.key === 'finishDate') {
        aValue = new Date(a.finishDate * 1000).getTime();
        bValue = new Date(b.finishDate * 1000).getTime();
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const getSortIcon = key => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const handleViewDouble = (doubleId, doubleName) => {
    dispatch(setGameName(doubleName));
    dispatch(setGameId(doubleId));
    dispatch(setGameType('doubles'));
    history.push(`/home/doubles/${doubleId}/${doubleName}`);
  };

  const handleBetDouble = (doubleId, doubleName) => {
    dispatch(setGameName(doubleName));
    dispatch(setGameId(doubleId));
    dispatch(setGameType('doubles'));
    history.push(`/home/doubles/${doubleId}/${doubleName}/competitions`);
  };

  const renderMobileTable = (double, index) => (
    <div key={double._id} className={styles.mobileCard}>
      <div className={styles.mobileCardHeader}>
        <span className={styles.mobileId}>#{index + 1}</span>
        <h3 className={styles.mobileName}>{double.name}</h3>
      </div>
      <div className={styles.mobileCardBody}>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('doubleCard.participants')}
          </span>
          <span className={styles.mobileValue}>
            {double.participants.length}/{double.maxParticipants}
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('doubleCard.finishDate')}
          </span>
          <span className={styles.mobileValue}>
            {new Date(double.finishDate * 1000).toLocaleDateString()}
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>{t('doubleCard.award')}</span>
          <span className={styles.mobileValue}>{double.pot.toFixed(2)}€</span>
        </div>
      </div>
      <div className={styles.mobileActions}>
        <button
          onClick={() => handleViewDouble(double._id, double.name)}
          className={styles.viewButton}
        >
          {t('doubleCard.view')}
        </button>
        {!double.hasBet && double.condition !== 'closed' && (
          <button
            onClick={() => handleBetDouble(double._id, double.name)}
            className={styles.betButton}
          >
            {t('doubleCard.bet')}
          </button>
        )}
      </div>
    </div>
  );

  const renderDesktopTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.doublesTable}>
        <thead>
          <tr>
            <th
              onClick={() => handleSort('id')}
              className={styles.sortableHeader}
            >
              ID {getSortIcon('id')}
            </th>
            <th
              onClick={() => handleSort('nameDouble')}
              className={styles.sortableHeader}
            >
              {t('doubleCard.name')} {getSortIcon('nameDouble')}
            </th>
            <th
              onClick={() => handleSort('participants')}
              className={styles.sortableHeader}
            >
              {t('doubleCard.participants')} {getSortIcon('participants')}
            </th>
            <th
              onClick={() => handleSort('finishDate')}
              className={styles.sortableHeader}
            >
              {t('doubleCard.finishDate')} {getSortIcon('finishDate')}
            </th>
            <th
              onClick={() => handleSort('potToWinners')}
              className={styles.sortableHeader}
            >
              {t('doubleCard.award')} {getSortIcon('potToWinners')}
            </th>
            <th>{t('common.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {getSortedDoubles().map((double, index) => (
            <tr key={double._id} className={styles.doubleRow}>
              <td>{index + 1}</td>
              <td className={styles.nameCell}>
                <div className={styles.nameContainer}>
                  <img
                    className={styles.doubleImage}
                    src={double.photo}
                    alt={double.nameDouble}
                  />
                  <span className={styles.doubleName}>{double.name}</span>
                </div>
              </td>
              <td>
                {double.participants.length}/{double.maxParticipants}
              </td>
              <td>{new Date(double.finishDate * 1000).toLocaleDateString()}</td>
              <td>{double.pot.toFixed(2)}€</td>
              <td className={styles.actions}>
                <button
                  onClick={() => handleViewDouble(double._id, double.name)}
                  className={styles.viewButton}
                >
                  {t('doubleCard.view')}
                </button>
                {!double.hasBet && double.condition !== 'closed' && (
                  <button
                    onClick={() => handleBetDouble(double._id, double.name)}
                    className={styles.betButton}
                  >
                    {t('doubleCard.bet')}
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (isLoading) {
    return (
      <>
        <Navigation />
        <div className={styles.loading}>
          <div className={styles.loadingSpinner}></div>
          <p>{t('common.loading')}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <h1 className={styles.title}>{t('myDoubles.title')}</h1>
        {myDoubles.length === 0 ? (
          <div className={styles.emptyState}>
            <p>{t('myDoubles.noDoubles')}</p>
            <button onClick={() => history.push('/home/doubles/all-doubles')}>
              {t('myDoubles.seeDoubles')}
            </button>
          </div>
        ) : (
          <>
            <div className={styles.desktopView}>{renderDesktopTable()}</div>
            <div className={styles.mobileView}>
              {getSortedDoubles().map((double, index) =>
                renderMobileTable(double, index)
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyDoubles;
