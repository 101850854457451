import React, {useEffect, useState, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {AuthContext} from '../../context/auth.context';
import SubNavbar from '../../components/SubNavbar';
import Navigation from '../../components/Navbar';
import {useTranslation} from 'react-i18next';
import styles from './myBets.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const getBetLabel = bet => {
  const {
    betSigne,
    betSigneSelected,
    betTeamHome,
    betTeamAway,
    betHandicap,
    betHandicapSigne,
    betNumberOverUnder,
    betKey,
  } = bet;

  const betLabelsFutbol = {
    full_time_result: () =>
      betSigne === '1' ? betTeamHome : betSigne === '2' ? betTeamAway : 'Draw',
    double_chance: () => betSigne,
    both_teams_to_score: () => betSigne,
    goals_over_under: () => `${betSigneSelected} ${betSigne}`,
    half_time_result: () =>
      betSigne === '1' ? betTeamHome : betSigne === '2' ? betTeamAway : 'Draw',
    alternative_handicap_result: () =>
      betSigneSelected === betTeamHome
        ? `${betTeamHome} ${betHandicapSigne === '-' ? '' : betHandicapSigne} ${betHandicap}`
        : betSigneSelected === betTeamAway
          ? `${betTeamAway} ${betHandicapSigne === '-' ? '' : betHandicapSigne} ${betHandicap}`
          : `Draw ${betHandicapSigne === '-' ? '' : betHandicapSigne} ${betHandicap}`,
    team_totals: () => `${betHandicapSigne} ${betHandicap} ${betSigneSelected}`,
    asian_handicap: () =>
      `${betSigneSelected === '1' ? betTeamHome : betTeamAway} ${betHandicap}`,
    goal_line: () => `${betSigneSelected} ${betSigne}`,
    draw_no_bet: () => (betSigne === '1' ? betTeamHome : betTeamAway),
    result_total_goals: () =>
      `${betSigne === '1' ? betTeamHome : betTeamAway} & ${betSigneSelected} ${betHandicap}`,
    total_goals_both_teams_to_score: () => `${betSigne} both teams to score`,
    exact_total_goals: () => `Exactly ${betSigne} goals`,
    goals_odd_even: () => `${betSigne} number of goals`,

    betBothTeamToScoreYes: () => 'Both teams to score: Yes',
    betBothTeamToScoreNo: () => 'Both teams to score: No',
    betHomeDraw: () => `${betTeamHome} & Draw`,
    betAwayDraw: () => `${betTeamAway} & Draw`,
    betHomeAway: () => `${betTeamHome} & ${betTeamAway}`,
    betWinEitherAway: () => `${betTeamAway} win either half`,
    betWinEitherHome: () => `${betTeamHome} win either half`,
    betBothAway: () => `${betTeamAway} win both half`,
    betBothHome: () => `${betTeamHome} win both half`,
    betGoalToNilAway: () => `${betTeamAway} win to nil`,
    betGoalToNilHome: () => `${betTeamHome} win to nil`,
    betFutballCornersUnder: () => `under than ${betNumberOverUnder} corners`,
    betFutballCornersOver: () => `more than ${betNumberOverUnder} corners`,
    betFutballCornersExact: () => `${betNumberOverUnder} corners`,
    futballAwayFirst: () => `${betTeamAway} win first half`,
    futballDrawFirst: () => 'draw first half',
    futballHomeFirst: () => `${betTeamHome} win first half`,
    betHandicapFutballAway: () => `${betTeamAway} win ${betHandicap} goals`,
    betHandicapFutballAway2: () => `${betTeamAway} win ${betHandicap} goals`,
    betHandicapFutballAway3: () => `${betTeamAway} win ${betHandicap} goals`,
    betHandicapFutballAway4: () => `${betTeamAway} win ${betHandicap} goals`,
    betHandicapFutballHome: () => `${betTeamHome} win ${betHandicap} goals`,
    betHandicapFutballHome2: () => `${betTeamHome} win ${betHandicap} goals`,
    betHandicapFutballHome3: () => `${betTeamHome} win ${betHandicap} goals`,
    betHandicapFutballHome4: () => `${betTeamHome} win ${betHandicap} goals`,
  };

  const betLabelsBasquet = {
    full_time_result: () =>
      betSigne === 'Money Line' && betSigneSelected === 'teamhome'
        ? betTeamHome
        : betSigne === 'Money Line' && betSigneSelected === 'teamaway'
          ? betTeamAway
          : betSigne === 'Spread' && betSigneSelected === 'teamhome'
            ? `${betHandicapSigne}${betHandicap} ${betTeamHome}`
            : betSigne === 'Spread' && betSigneSelected === 'teamaway'
              ? `${betHandicap} ${betTeamAway}`
              : betSigne === 'Total' && betSigneSelected === 'teamhome'
                ? `${betHandicapSigne} ${betHandicap}`
                : `${betHandicapSigne} ${betHandicap}`,
    double_result: () => betSigne,
    match_result_and_total: () =>
      betSigneSelected === 'teamhome'
        ? `${betTeamHome} & ${betHandicapSigne} ${betHandicap}`
        : `${betTeamAway} & ${betHandicapSigne} ${betHandicap}`,
    match_handicap_and_total: () => `${betSigne}`,
    winning_margin: () =>
      betSigne === 'teamhome'
        ? `${betTeamHome} ${betHandicapSigne}${betSigne}`
        : `${betTeamAway} ${betHandicapSigne}${betSigne}`,
    first_half: () =>
      betSigne === 'Money Line' && betSigneSelected === 'teamhome'
        ? betTeamHome
        : betSigne === 'Money Line' && betSigneSelected === 'teamaway'
          ? betTeamAway
          : betSigne === 'Spread' && betSigneSelected === 'teamhome'
            ? `${betHandicapSigne}${betHandicap} ${betTeamHome}`
            : betSigne === 'Spread' && betSigneSelected === 'teamaway'
              ? `${betHandicapSigne}${betHandicap} ${betTeamAway}`
              : betSigne === 'Total' && betSigneSelected === 'teamhome'
                ? `${betHandicapSigne} ${betHandicap}`
                : `${betHandicapSigne} ${betHandicap}`,
    first_half_handicap_and_total: () => betSigne,
    first_half_team_totals: () =>
      betSigne === 'teamhome'
        ? `${betTeamHome} ${betHandicapSigne} ${betHandicap}`
        : `${betTeamAway} ${betHandicapSigne} ${betHandicap}`,
    first_half_result_and_total: () =>
      betSigne === '1'
        ? `${betTeamHome} and ${betSigneSelected}${betHandicap}`
        : `${betTeamAway} and ${betSigneSelected}${betHandicap}`,
    first_half_both_teams_to_score_x_points: () =>
      `${betHandicapSigne}${betSigne} ${betSigneSelected}`,
    first_half_money_line_3_way: () =>
      betSigne === '1' ? betTeamHome : betSigne === '2' ? betTeamAway : 'Tie',
  };

  if (bet.betSport === 'futbol') {
    return (
      betLabelsFutbol[betKey] || (() => `${betSigneSelected} ${betSigne}`)
    )();
  }

  if (bet.betSport === 'basquet') {
    return (
      betLabelsBasquet[betKey] || (() => `${betSigneSelected} ${betSigne}`)
    )();
  }
};

const BetTicket = React.memo(({bet}) => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.ticket} ${styles[`ticket-${bet?.status}`]}`}>
      <div className={styles.ticketHeader}>{bet?.betMatch}</div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.eventDate')}: </span>
        <span className={styles.ticketValue}>
          {new Date(bet.matchTime * 1000).toLocaleString()}
        </span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.betType')}: </span>
        <span className={styles.ticketValue}>
          {bet.betName === 'full time result' ? bet?.betName : bet?.betType}
        </span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.bet')}: </span>
        <span className={styles.ticketValue}>{getBetLabel(bet)}</span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.betResult')}: </span>
        <span className={styles.ticketValue}>
          {bet.result === '' ? t('myBets.betResultPending') : bet?.result}
        </span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={`${styles.status} ${styles[`status-${bet?.status}`]}`}>
          {bet.status}
        </span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.coinsBet')}: </span>
        <span className={styles.ticketValue}>{bet?.betAmount}</span>
      </div>

      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>
          {t('myBets.potentialCoins')}:{' '}
        </span>
        <span className={styles.ticketValue}>{bet?.coinsToWin.toFixed(2)}</span>
      </div>
    </div>
  );
});

BetTicket.propTypes = {
  bet: PropTypes.shape({
    status: PropTypes.string.isRequired,
    matchTime: PropTypes.number.isRequired,
    betMatch: PropTypes.string.isRequired,
    betName: PropTypes.string.isRequired,
    betType: PropTypes.string.isRequired,
    result: PropTypes.string.isRequired,
    betAmount: PropTypes.number.isRequired,
    coinsToWin: PropTypes.number.isRequired,
  }).isRequired,
};

BetTicket.displayName = 'BetTicket';

const CombinationBetTicket = React.memo(({bet}) => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.ticket} ${styles[`ticket-${bet?.status}`]}`}>
      <div className={styles.ticketHeader}>
        <span className={styles.combinationLabel}>
          {t('myBets.combinationBet')}
        </span>
      </div>

      {bet.bets.map((singleBet, index) => (
        <div key={singleBet._id} className={styles.combinationBetItem}>
          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>
              {t('myBets.match')} {index + 1}:{' '}
            </span>
            <span className={styles.ticketValue}>{singleBet.betMatch}</span>
          </div>

          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>{t('myBets.betType')}: </span>
            <span className={styles.ticketValue}>
              {singleBet.betName === 'full time result'
                ? singleBet.betName
                : singleBet.betType}
            </span>
          </div>

          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>{t('myBets.bet')}: </span>
            <span className={styles.ticketValue}>{getBetLabel(singleBet)}</span>
          </div>

          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>
              {t('myBets.betResult')}:{' '}
            </span>
            <span className={styles.ticketValue}>
              {singleBet.result === 'pending'
                ? t('myBets.betResultPending')
                : singleBet.result}
            </span>
          </div>
        </div>
      ))}

      <div className={styles.ticketFooter}>
        <div className={styles.ticketInfo}>
          <span
            className={`${styles.status} ${styles[`status-${bet?.status}`]}`}
          >
            {bet.status}
          </span>
        </div>

        <div className={styles.ticketInfo}>
          <span className={styles.ticketLabel}>{t('myBets.coinsBet')}: </span>
          <span className={styles.ticketValue}>{bet?.betAmount}</span>
        </div>

        <div className={styles.ticketInfo}>
          <span className={styles.ticketLabel}>
            {t('myBets.potentialCoins')}:{' '}
          </span>
          <span className={styles.ticketValue}>
            {bet?.coinsToWin.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
});

CombinationBetTicket.propTypes = {
  bet: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    betAmount: PropTypes.number.isRequired,
    coinsToWin: PropTypes.number.isRequired,
    bets: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        betMatch: PropTypes.string.isRequired,
        betType: PropTypes.string.isRequired,
        betName: PropTypes.string,
        result: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        betSport: PropTypes.string.isRequired,
        betSigne: PropTypes.string,
        betSigneSelected: PropTypes.string,
        betHandicap: PropTypes.number,
        betHandicapSigne: PropTypes.string,
        betTeamHome: PropTypes.string,
        betTeamAway: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

CombinationBetTicket.displayName = 'CombinationBetTicket';

const MyBetsPage = ({match}) => {
  const {t} = useTranslation();
  const {id: leagueId} = match.params;
  const {isLoggedIn} = useContext(AuthContext);
  const [mybets, setMybets] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {betLeagueId, gameMode, betLeagueName} = useSelector(
    state => state.bet
  );
  const {userId} = useSelector(state => state.user);

  const getAllbets = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/get-mybets`, {
        leagueId,
        userId,
      });
      setMybets(response.data[0]);
      setError(null);
    } catch (error) {
      setError(t('myBets.error'));
      console.error('Error fetching bets:', error);
    } finally {
      setIsLoading(false);
    }
  }, [leagueId, userId, t]);

  useEffect(() => {
    if (isLoggedIn) {
      getAllbets();
    }
  }, [isLoggedIn, getAllbets]);

  return (
    <>
      <Navigation />
      <SubNavbar id={betLeagueId} game={gameMode} leagueName={betLeagueName} />
      <div className={styles.container}>
        <h1 className={styles.title}>{t('myBets.title')}</h1>

        {isLoading ? (
          <div className={styles.loading}>{t('myBets.loading')}</div>
        ) : error ? (
          <div className={styles.error}>{error}</div>
        ) : (
          <>
            <div className={styles.coinsInfo}>
              <span className={styles.coinsAmount}>
                {mybets?.inPlayCoins} {t('myBets.coinsInPlay')}
              </span>
            </div>

            <div className={styles.ticketContainer}>
              {/* Regular bets */}
              {mybets?.bets?.map(bet => (
                <BetTicket key={bet._id} bet={bet} />
              ))}

              {/* Combination bets */}
              {mybets?.combinationBets?.map(bet => (
                <CombinationBetTicket key={bet._id} bet={bet} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

MyBetsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MyBetsPage;
