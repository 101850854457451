import {useState, useEffect} from 'react';
import axios from 'axios';

export const useQuizExistence = (userId, leagueId, isLoggedIn) => {
  const [quizExists, setQuizExists] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      const findQuiz = {
        userId,
        leagueId,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/exist-quiz`, findQuiz)
        .then(response => {
          setQuizExists(response.data.length !== 0);
        });
    }
  }, [isLoggedIn, userId, leagueId]);

  return {quizExists};
};
