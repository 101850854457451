import {useEffect, useState, useContext} from 'react';
import {AuthContext} from '../../context/auth.context';
import axios from 'axios';
import {Link} from 'react-router-dom';

function NotificationsPage() {
  const API_URL = process.env.REACT_APP_API_URL;
  const {user, isLoggedIn} = useContext(AuthContext);
  const [userNotifications, setUserNotifications] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (isLoggedIn) {
      const userId = {
        userId: user._id,
      };
      axios.post(`${API_URL}/notifications`, userId).then(response => {
        setUserNotifications(response.data);
        setLoading(false);
      });
      setTimeout(() => {
        axios.post(`${API_URL}/notifications-delete`, userId).then(response => {
          console.log(response);
        });
      }, 1000);
    }
  }, [isLoggedIn]);

  if (loading) {
    return <h1>Loading</h1>;
  } else {
    return (
      <>
        <div>
          <div className="leagues-container">
            <h1>You have the following notifications: </h1>
          </div>
          {userNotifications.notifications.map(notification => (
            <>
              <div className="">
                <h3>{notification.title}</h3>
                <h4>{notification.text}</h4>
                <h5>
                  <Link to={notification.link}> Go to the panel league </Link>
                </h5>
              </div>
            </>
          ))}
        </div>
      </>
    );
  }
}

export default NotificationsPage;
