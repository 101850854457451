import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styles from './card.module.css';

const Card = ({
  id,
  source,
  imageSrc,
  altText,
  title,
  description,
  link,
  btnText,
  'data-testid': dataTestId,
}) => {
  const isSourceModes = source === 'Modes';
  return (
    <div
      id={id}
      className={isSourceModes ? styles.cardModes : styles.cardGame}
      data-testid={dataTestId}
    >
      <img src={imageSrc} alt={altText} className={styles.cardImage} />
      <div className={styles.cardContent}>
        <div
          className={
            isSourceModes ? styles.cardWrapperModes : styles.cardWrapperGame
          }
        >
          <div
            className={
              isSourceModes
                ? styles.cardContentLeftModes
                : styles.cardContentLeftGame
            }
          >
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className={styles.cardContentRight}>
            <Link to={link}>
              <button>{btnText}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  source: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

export default Card;
