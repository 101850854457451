import {useState} from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './StripePaymentForm.module.css';

const StripePaymentForm = ({
  amount,
  onPaymentIntent,
  onPaymentSuccess,
  isProcessing,
}) => {
  const {t} = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const clientSecret = await onPaymentIntent();

    if (!clientSecret) {
      return;
    }

    const {error, paymentIntent} = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardholderName,
          },
        },
      }
    );

    if (error) {
      console.error('Payment error:', error);
    } else if (paymentIntent.status === 'succeeded') {
      onPaymentSuccess(paymentIntent.id);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
        {/*      <label htmlFor="cardholderName" className={styles.label}>
          {t('depositPage.cardholderName')}
        </label> */}
        <input
          type="text"
          id="cardholderName"
          value={cardholderName}
          onChange={e => setCardholderName(e.target.value)}
          required
          className={styles.input}
          placeholder={t('depositPage.cardholderNamePlaceholder')}
        />
      </div>
      <div className={styles.formGroup}>
        {/*       <label htmlFor="cardElement" className={styles.label}>
          {t('depositPage.cardDetails')}
        </label> */}
        <div className={styles.cardElement}>
          <CardNumberElement options={cardElementOptions} />
        </div>
        <div className={styles.cardDetailsRow}>
          <div className={`${styles.cardElement} ${styles.expiryElement}`}>
            <CardExpiryElement options={cardElementOptions} />
          </div>
          <div className={`${styles.cardElement} ${styles.cvcElement}`}>
            <CardCvcElement options={cardElementOptions} />
          </div>
        </div>
      </div>
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className={styles.submitButton}
      >
        {isProcessing
          ? t('depositPage.processing')
          : t('depositPage.payButton', {amount})}
      </button>
    </form>
  );
};

StripePaymentForm.propTypes = {
  amount: PropTypes.string.isRequired,
  onPaymentIntent: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default StripePaymentForm;
