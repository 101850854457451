import {useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'axios';
import styles from './resetPassword.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const {token} = useParams();
  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/auth/reset-password/${token}`,
        {password}
      );
      setMessage(response.data.message);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className={styles.resetPasswordContainer}>
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="password">New Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="confirmPassword">Confirm New Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.resetButton}>
          Reset Password
        </button>
      </form>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default ResetPassword;
