import {useContext, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {AuthContext} from '../../context/auth.context';
import Joyride, {STATUS} from 'react-joyride';
import {incrementOnboardingStep, resetOnboarding} from '../../redux/userSlice';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import Card from '../../components/card/Card';
import styles from './homeLeague.module.css';
import nuevaLiga from './nuevaLiga.png';
import doubles from './doubles.png';

function HomeLeagues() {
  const {isLoggedIn} = useContext(AuthContext);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser]);

  const steps = [
    {
      target: 'body',
      content: t('homeLeagues.onboarding.welcome'),
      placement: 'center',
      disableBeacon: true,
    },
    {
      target: '#card-0',
      content: t('homeLeagues.onboarding.newLeague'),
    },
    {
      target: '#card-1',
      content: t('homeLeagues.onboarding.myLeagues'),
    },
    {
      target: '#card-2',
      content: t('homeLeagues.onboarding.allLeagues'),
    },
    {
      target: '#card-2 button',
      content: t('homeLeagues.onboarding.joinAllLeagues'),
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  const cardData = [
    {
      id: 'card-0',
      imageSrc: nuevaLiga,
      altText: t('homeLeagues.newLeague.title'),
      title: t('homeLeagues.newLeague.title'),
      description: t('homeLeagues.newLeague.description'),
      source: 'Games',
      btnText: t('homeLeagues.newLeague.button'),
      link: '/home/leagues/create-league',
    },
    {
      id: 'card-1',
      imageSrc: doubles,
      altText: t('homeLeagues.myLeagues.title'),
      title: t('homeLeagues.myLeagues.title'),
      description: t('homeLeagues.myLeagues.description'),
      source: 'Games',
      btnText: t('homeLeagues.myLeagues.button'),
      link: '/home/leagues/my-leagues',
    },
    {
      id: 'card-2',
      imageSrc: doubles,
      altText: t('homeLeagues.allLeagues.title'),
      title: t('homeLeagues.allLeagues.title'),
      description: t('homeLeagues.allLeagues.description'),
      source: 'Games',
      btnText: t('homeLeagues.allLeagues.button'),
      link: '/home/leagues/all-leagues',
    },
  ];

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <main className={styles.main}>
          <div className={styles.contentWrapper}>
            <div className={styles.contentTitle}>
              <h1>{t('homeLeagues.welcome')}</h1>
            </div>
            <div className={styles.cardContainer}>
              {cardData.map(card => (
                <Card
                  key={card.id}
                  id={card.id}
                  imageSrc={card.imageSrc}
                  altText={card.altText}
                  title={card.title}
                  description={card.description}
                  source={card.source}
                  btnText={card.btnText}
                  link={card.link}
                />
              ))}
            </div>
          </div>
        </main>
      </div>
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        stepIndex={onboardingStep}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#248061',
          },
          buttonNext: {
            backgroundColor: '#248061',
          },
          buttonBack: {
            color: '#248061',
          },
        }}
      />
    </>
  );
}

export default HomeLeagues;
