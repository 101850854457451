import {useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import liga from '../HomePage/assets/ligas.png';
import qa from '../HomePage/assets/qa.jpg';
import styles from './recoverPassword.module.css';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  resetEmail: '',
  resetMessage: '',
  isLoading: false,
};

const RecoverPassword = () => {
  const {t} = useTranslation();
  const [state, setState] = useState(initialState);

  const handleChange = useCallback(e => {
    const {name, value} = e.target;
    setState(prevState => ({...prevState, [name]: value}));
  }, []);

  const handleScrollClick = useCallback(() => {
    const ctaSection = document.getElementById('ctaSection');
    if (ctaSection) {
      ctaSection.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, []);

  const handleResetPassword = useCallback(
    async e => {
      e.preventDefault();
      setState(prev => ({...prev, isLoading: true}));
      try {
        const response = await axios.post(`${API_URL}/auth/reset-password`, {
          email: state.resetEmail,
        });
        setState(prevState => ({
          ...prevState,
          resetMessage: response.data.message,
          resetEmail: '',
          isLoading: false,
        }));
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          resetMessage: error.response?.data?.message || 'An error occurred',
          isLoading: false,
        }));
      }
    },
    [state.resetEmail]
  );

  const {resetEmail, resetMessage, isLoading} = state;

  return (
    <div className={styles.App}>
      <div className={styles.languageSwitcherContainer}>
        <Link to="/qa">
          <img src={qa} alt="Q&A" className={styles.qaIcon} />
        </Link>
        <LanguageSwitcher />
      </div>

      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <p>{t('recoverPassword.hero.description')}</p>
        </div>
        <div className={styles.scrollIndicator} onClick={handleScrollClick}>
          <span>{t('recoverPassword.form.scroll')}</span>
          <div className={styles.scrollLine}></div>
        </div>
      </section>

      <section className={styles.cta} id="ctaSection">
        <div className={styles.ctaContent}>
          <h1>{t('recoverPassword.form.title')}</h1>
          <h3>{t('login.form.subtitle')}</h3>
          <form onSubmit={handleResetPassword} className={styles.ctaForm}>
            <input
              type="email"
              name="resetEmail"
              value={resetEmail}
              onChange={handleChange}
              placeholder={t('recoverPassword.form.emailPlaceholder')}
              required
              className={styles.ctaInput}
            />
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? '...' : t('recoverPassword.form.submitButton')}
            </button>
            {resetMessage && (
              <div
                className={`${styles.message} ${
                  resetMessage.includes('error')
                    ? styles.errorMessage
                    : styles.successMessage
                }`}
              >
                {resetMessage}
              </div>
            )}
            <div className={styles.links}>
              <p>
                {t('recoverPassword.form.noAccount')}{' '}
                <Link to="/signup">
                  {t('recoverPassword.form.createAccount')}
                </Link>
              </p>
              <Link to="/login" className={styles.loginLink}>
                {t('common.login')}
              </Link>
            </div>
          </form>
        </div>
        <div className={styles.ctaImage}>
          <img src={liga} alt="Celebrating team" />
        </div>
      </section>
    </div>
  );
};

export default RecoverPassword;
