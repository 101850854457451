import {useCallback} from 'react';
import axios from 'axios';
import {setGameName, setGameId, setGameType} from '../redux/actualGameSlice';

const useJoinLeague = (
  API_URL,
  user,
  userCash,
  dispatch,
  history,
  setUserMoney,
  updateOpenLeagues
) => {
  const handleJoinLeague = useCallback(
    async (league, accessCode) => {
      if (league.accessCode && league.accessCode !== accessCode) {
        return;
      }

      const potToUpdate =
        Number(league.pot) + Number(league.inscriptionPrice * 0.9);

      const joinLeagueInfo = {
        userId: user._id,
        leagueId: league._id,
        leagueName: league.nameLeague,
        coinsUpdated: userCash - league.inscriptionPrice,
        eurosPlayed: Number(league.inscriptionPrice),
        potUpdated: potToUpdate,
        potBetAndFriendsUpdated: league.inscriptionPrice * 0.1,
        numberCoinsInLeague: league.numberCoinsInLeague,
        inscriptionPrice: league.inscriptionPrice,
      };

      try {
        await axios.post(`${API_URL}/join-league`, joinLeagueInfo);
        dispatch(setUserMoney(userCash - league.inscriptionPrice));
        dispatch(updateOpenLeagues(joinLeagueInfo));
        dispatch(setGameName(league.nameLeague));
        dispatch(setGameId(league._id));
        dispatch(setGameType('leagues'));
        history.push(`/home/leagues/${league._id}/${league.nameLeague}`);
      } catch (error) {
        console.error(error);
      }
    },
    [
      API_URL,
      dispatch,
      history,
      setUserMoney,
      updateOpenLeagues,
      user._id,
      userCash,
    ]
  );

  return {handleJoinLeague};
};

export default useJoinLeague;
