import {useState, useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import {
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import {styled} from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;

const StyledContainer = styled(Container)(({theme}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTitle = styled(Typography)(({theme}) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({theme}) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const StyledBackButton = styled(Link)(({theme}) => ({
  display: 'inline-block',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textDecoration: 'none',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeFilter, setTimeFilter] = useState('all');
  const {token, userId} = useSelector(state => state.user);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${API_URL}/dashboard`, {
          headers: {Authorization: `Bearer ${token}`},
          params: {timeFilter, userId},
        });
        setDashboardData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Error fetching dashboard data');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [token, timeFilter]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const userTypeData = [
    {name: 'Active Users', value: dashboardData.activeUsers},
    {
      name: 'Inactive Users',
      value: dashboardData.totalUsers - dashboardData.activeUsers,
    },
  ];

  return (
    <StyledContainer>
      <StyledTitle variant="h4">Admin Dashboard</StyledTitle>

      <StyledBackButton to="/admin">Back to Admin Home</StyledBackButton>

      <StyledFormControl>
        {/*  <InputLabel>Time Filter</InputLabel> */}
        <Select
          value={timeFilter}
          onChange={e => setTimeFilter(e.target.value)}
        >
          <MenuItem value="all">All Time</MenuItem>
          <MenuItem value="month">Last Month</MenuItem>
          <MenuItem value="week">Last Week</MenuItem>
        </Select>
      </StyledFormControl>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4">{dashboardData.totalUsers}</Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <Typography variant="h6">Total Deposits</Typography>
            <Typography variant="h4">
              €{dashboardData.totalDepositAmount.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <Typography variant="h6">Total Withdrawals</Typography>
            <Typography variant="h4">
              €{dashboardData.totalWithdrawalAmount.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledPaper>
            <Typography variant="h6">Total Euros Played</Typography>
            <Typography variant="h4">
              €{dashboardData.totalEurosPlayed.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6">User Activity</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={userTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {userTypeData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6">Deposits vs Withdrawals</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={[
                  {name: 'Deposits', amount: dashboardData.totalDepositAmount},
                  {
                    name: 'Withdrawals',
                    amount: dashboardData.totalWithdrawalAmount,
                  },
                ]}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Typography variant="h6">Avg Deposit per User</Typography>
            <Typography variant="h4">
              €{dashboardData.avgDepositPerUser.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Typography variant="h6">Avg Withdrawal per User</Typography>
            <Typography variant="h4">
              €{dashboardData.avgWithdrawalPerUser.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Typography variant="h6">Avg Euros Played per User</Typography>
            <Typography variant="h4">
              €{dashboardData.avgEurosPlayedPerUser.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <Typography variant="h6">Avg League Price</Typography>
            <Typography variant="h4">
              €{dashboardData.avgLeaguePrice.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <Typography variant="h6">Avg Double Price</Typography>
            <Typography variant="h4">
              €{dashboardData.avgDoublePrice.toFixed(2)}
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dashboard;
