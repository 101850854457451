import {useState, useContext, useCallback, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {AuthContext} from '../../context/auth.context';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useSelector, useDispatch} from 'react-redux';
import {setGameType, setGameId, setGameName} from '../../redux/actualGameSlice';
import {setUserMoney} from '../../redux/userSlice';
import {updateOpenLeagues} from '../../redux/leagueSlice';
import {useTranslation} from 'react-i18next';
import styles from './createLeague.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const initialFormData = {
  leagueName: '',
  inscriptionPrice: '',
  maxParticipants: '',
  numberCoinsInLeague: '',
  accessCode: '',
  participants: '',
  potDistribution: 'allWinners',
  condition: 'open',
  finishDate: '',
  photo: null,
};

const CreateLeague = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isLoggedIn} = useContext(AuthContext);
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const {userMoney, userId} = useSelector(state => state.user);
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => setError(null), 3000);
    return () => clearTimeout(timeout);
  }, [error]);

  const handleChange = useCallback(e => {
    const {name, value} = e.target;
    setFormData(prevState => ({...prevState, [name]: value}));
  }, []);

  const validateForm = useCallback(() => {
    const {
      leagueName,
      inscriptionPrice,
      maxParticipants,
      numberCoinsInLeague,
      finishDate,
    } = formData;

    if (
      [
        leagueName,
        inscriptionPrice,
        maxParticipants,
        numberCoinsInLeague,
        finishDate,
      ].some(field => field === '')
    ) {
      setError(t('createLeague.completeForm'));
      return false;
    }

    if (Number(inscriptionPrice) > userMoney) {
      setError(t('createLeague.insufficientFunds'));
      return false;
    }

    return true;
  }, [formData, userMoney, t]);

  const handleImageUpload = useCallback(async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'betandfriends');

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/dpkplya7n/image/upload',
        formData
      );
      setImageUrl(response.data.secure_url);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Error uploading image. Please try again.');
    }
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (!validateForm()) return;

      const newLeague = {
        ...formData,
        nameLeague: formData.leagueName,
        inscriptionPrice: Number(formData.inscriptionPrice),
        maxParticipants: Number(formData.maxParticipants),
        numberCoinsInLeague: Number(formData.numberCoinsInLeague),
        finishDate: parseInt(formData.finishDate) * 86400 + Date.now() / 1000,
        userId,
        photo:
          imageUrl ||
          'https://res.cloudinary.com/dpkplya7n/image/upload/v1699700824/BetandfriendsProfile/a2mcacenxvw3zm7o1huy.png',
      };

      try {
        const storedToken = localStorage.getItem('authToken');
        const response = await axios.post(`${API_URL}/newleague`, newLeague, {
          headers: {Authorization: `Bearer ${storedToken}`},
        });

        dispatch(setUserMoney(userMoney - Number(formData.inscriptionPrice)));
        dispatch(updateOpenLeagues(newLeague));
        dispatch(setGameName(response.data.nameLeague));
        dispatch(setGameId(response.data._id));
        dispatch(setGameType('leagues'));

        history.push(`${response.data._id}/${response.data.nameLeague}`);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setError(
            error.response.data.message || t('createLeague.errorCreating')
          );
        } else {
          setError(t('createLeague.errorCreating'));
        }
        console.error('Error creating league:', error);
      }
    },
    [
      formData,
      validateForm,
      userId,
      imageUrl,
      userMoney,
      dispatch,
      history,
      API_URL,
      t,
    ]
  );

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        {isLoggedIn ? (
          <>
            <h1 className={styles.title}>{t('createLeague.title')}</h1>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <form className={styles.form} onSubmit={handleSubmit}>
              <input
                className={styles.input}
                type="text"
                name="leagueName"
                value={formData.leagueName}
                onChange={handleChange}
                placeholder={t('createLeague.leagueName')}
                maxLength={50}
                required
              />
              <input
                className={styles.input}
                type="number"
                name="inscriptionPrice"
                value={formData.inscriptionPrice}
                onChange={handleChange}
                placeholder={t('createLeague.inscriptionCost')}
                min="1"
                required
              />
              <input
                className={styles.input}
                type="number"
                name="maxParticipants"
                value={formData.maxParticipants}
                onChange={handleChange}
                placeholder={t('createLeague.maxParticipants')}
                min="2"
                required
              />
              <input
                className={styles.input}
                type="number"
                name="numberCoinsInLeague"
                value={formData.numberCoinsInLeague}
                onChange={handleChange}
                placeholder={t('createLeague.numberCoins')}
                min="1"
                required
              />
              <input
                className={styles.input}
                type="number"
                name="finishDate"
                value={formData.finishDate}
                onChange={handleChange}
                placeholder={t('createLeague.duration')}
                min="1"
                required
              />
              <input
                className={styles.input}
                type="text"
                name="accessCode"
                value={formData.accessCode}
                onChange={handleChange}
                placeholder={t('createLeague.accessCode')}
                maxLength={20}
              />
              <select
                className={styles.select}
                name="potDistribution"
                value={formData.potDistribution}
                onChange={handleChange}
                required
              >
                <option value="allWinners">
                  {t('createLeague.potDistribution.allWinners')}
                </option>
                <option value="first-second-third">
                  {t('createLeague.potDistribution.first-second-third')}
                </option>
                <option value="half">
                  {t('createLeague.potDistribution.half')}
                </option>
              </select>
              <div className={styles.fileInput}>
                <input
                  type="file"
                  name="photo"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </div>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="League"
                  className={styles.previewImage}
                />
              )}
              <button className={styles.btn} type="submit">
                {t('createLeague.createButton')}
              </button>
            </form>
          </>
        ) : (
          <h4 className={styles.loginMessage}>
            {t('createLeague.loginToCreate')}{' '}
            <Link to="/login">{t('common.login')}</Link> or{' '}
            <Link to="/signup">{t('common.signup')}</Link>
          </h4>
        )}
      </div>
    </>
  );
};

export default CreateLeague;
