import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  betOdd,
  betName,
  betSigne,
  betSigneSelected,
  betHandicap,
  betType,
  setBetKey,
  betMatch,
  addSelectedBet,
} from '../redux/betSlice';
import Joyride, {STATUS} from 'react-joyride';
import {useTranslation} from 'react-i18next';
import {incrementOnboardingStep, resetOnboarding} from '../redux/userSlice';
import {Button} from 'react-bootstrap';
import styles from './matchDetails.module.css';

const MatchDetails = ({
  matchDetails,
  isModalOpen,
  setIsModalOpen,
  showMatchDetails,
  setShowMatchDetails,
  isMobile,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {betTeamHome, betTeamAway} = useSelector(state => state.bet);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (showMatchDetails && isNewUser) {
      setRunTour(true);
      setShowMatchDetails(false);
    }
  }, [showMatchDetails, isNewUser, setShowMatchDetails]);

  const steps = [
    {
      target: '#match-details-container',
      content: t('matchDetails.onboarding.fullTimeResult'),
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      target: '#match-winner-section',
      content: t('matchDetails.onboarding.bettingOptions'),
      disableBeacon: true,
    },
    {
      target: '#first-bet-option',
      content: t('matchDetails.onboarding.clickToBet'),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  if (!matchDetails) {
    return null;
  }

  const renderModalBet = (
    odds,
    name,
    handicap,
    header,
    formattedKey,
    subkey,
    betKey
  ) => {
    header =
      header === '1' ? betTeamHome : header === '2' ? betTeamAway : header;

    // First dispatch all bet details
    dispatch(betMatch(`${betTeamHome} - ${betTeamAway}`));
    dispatch(betOdd(odds));
    dispatch(betName(formattedKey));
    dispatch(betHandicap(handicap));
    dispatch(betSigne(name));
    dispatch(betSigneSelected(header));
    dispatch(betType(subkey));
    dispatch(setBetKey(betKey));

    // If no duplicate, automatically add the bet

    dispatch(addSelectedBet());

    setIsModalOpen(true);
  };

  const renderBetOption = (bet, sectionName, betKey, index) => {
    if (bet.header !== 'Tie') {
      return (
        <div
          key={bet.id}
          className={styles.matchOddsContainer}
          id={
            index === 0 && betKey === 'full_time_result'
              ? 'first-bet-option'
              : undefined
          }
          onClick={() =>
            renderModalBet(
              bet.odds,
              bet.name,
              bet.handicap,
              bet.header,
              sectionName,
              sectionName,
              betKey,
              betTeamHome,
              betTeamAway
            )
          }
        >
          <p className={styles.odds}>{bet.odds}</p>
          {bet.name && (
            <p className={styles.betName}>
              {bet.name === '1'
                ? betTeamHome
                : bet.name === '2'
                  ? betTeamAway
                  : bet.name}
            </p>
          )}
          {bet.handicap && <p className={styles.handicap}>{bet.handicap}</p>}
          {bet.header && (
            <p className={styles.betHeader}>
              {bet.header === '1'
                ? betTeamHome
                : bet.header === '2'
                  ? betTeamAway
                  : bet.header}
            </p>
          )}
        </div>
      );
    }
    return null; // No renderizar nada si bet.header es "Tie"
  };

  const renderSection = (title, bets, key) => {
    if (!bets || bets.length === 0) return null;

    return (
      <div
        className={styles.betGroup}
        id={key === 'full_time_result' ? 'match-winner-section' : undefined}
      >
        <h5 className={styles.betSubtypeTitle}>{title}</h5>
        <div className={styles.betRow}>
          {bets.map((bet, index) => renderBetOption(bet, title, key, index))}
        </div>
      </div>
    );
  };

  const handleBackToList = () => {
    setShowMatchDetails(false);
  };

  const mainOddsKeys = {
    full_time_result: 'Match Winner',
    double_chance: 'Double Chance',
    goals_over_under: 'Goals Over/Under',
    both_teams_to_score: 'Both Teams to Score',
    half_time_result: 'Half Time Result',
    alternative_handicap_result: 'Alternative Handicap',
    team_totals: 'Team Totals',
    half_time_full_time: 'Half Time/Full Time',
    asian_handicap: 'Asian Handicap',
    // goal_line: 'Goal Line',
    draw_no_bet: 'Draw No Bet',
    result_total_goals: 'Result & Total Goals',
    total_goals_both_teams_to_score: 'Total goals both team to score',
    exact_total_goals: 'Exact total goals',
    goals_odd_even: 'Goals odd even',
  };

  return (
    <div
      className={`${styles.matchDetails} col-12 col-md-12 row ${isModalOpen ? styles.blur : ''}`}
      style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: '84vh'}}
    >
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        stepIndex={onboardingStep}
        callback={handleJoyrideCallback}
        hideBackButton={true}
        styles={{
          options: {
            primaryColor: '#007bff',
            zIndex: 1000,
          },
          tooltip: {
            fontSize: '14px',
            padding: '15px',
          },
          buttonNext: {
            backgroundColor: '#007bff',
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
      />

      {isMobile && (
        <Button onClick={handleBackToList} className={styles.backToListButton}>
          {t('matchDetails.backToList')}
        </Button>
      )}
      <div
        className={styles.matchDetailsContainer}
        id="match-details-container"
      >
        {Object.entries(mainOddsKeys).map(([key, title]) =>
          renderSection(title, matchDetails[key], key)
        )}
      </div>
    </div>
  );
};

MatchDetails.propTypes = {
  matchDetails: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  showMatchDetails: PropTypes.bool.isRequired,
  setShowMatchDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MatchDetails;
