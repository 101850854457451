import axios from 'axios';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  setIsAdmin,
  userName,
  userToken,
  userId,
  setUserMoney,
  setUserPhoto,
  setUserMail,
} from '../userSlice';
import {leaguesOutToLogin, leagueUpdate} from '../leagueSlice';

const API_URL = process.env.REACT_APP_API_URL;

export const login = credentials => async dispatch => {
  dispatch(loginStart());
  try {
    const response = await axios.post(`${API_URL}/auth/login`, credentials);
    const {authToken, user} = response.data;
    dispatch(loginSuccess({authToken, user}));
    dispatch(setIsAdmin(user.isAdmin));
    dispatch(userName(user.username));
    dispatch(userToken(authToken));
    dispatch(userId(user._id));
    dispatch(setUserMail(user.email));
    dispatch(leagueUpdate(user.openLeagues));
    dispatch(setUserMoney(user.coins));
    dispatch(setUserPhoto(user.photoProfile));
    dispatch(leaguesOutToLogin());
    dispatch(leagueUpdate(user.openLeagues));

    return {authToken, user}; // Return this so we can use it in the component if needed
  } catch (error) {
    dispatch(loginFailure());
    throw error; // Throw the error so we can handle it in the component
  }
};

// You can add other auth-related actions here (e.g., logout, signup)
