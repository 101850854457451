import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  betOdd,
  betName,
  betSigne,
  betSigneSelected,
  betHandicap,
  betType,
  setBetKey,
  betMatch,
  addSelectedBet,
} from '../redux/betSlice';
import Joyride, {STATUS} from 'react-joyride';
import {useTranslation} from 'react-i18next';
import {incrementOnboardingStep, resetOnboarding} from '../redux/userSlice';
import styles from './matchDetailsBasket.module.css';
import {Button} from 'react-bootstrap';

const MatchDetailsBasket = ({
  matchDetails,
  isModalOpen,
  setIsModalOpen,
  showMatchDetails,
  setShowMatchDetails,
  isMobile,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {betTeamHome, betTeamAway} = useSelector(state => state.bet);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    if (showMatchDetails && isNewUser) {
      setRunTour(true);
      setShowMatchDetails(false);
    }
  }, [showMatchDetails, isNewUser, setShowMatchDetails]);

  const steps = [
    {
      target: '.full_time_result',
      content: t('matchDetails.onboarding.fullTimeResult'),
      disableBeacon: true,
    },
    {
      target: '.match-details-container',
      content: t('matchDetails.onboarding.bettingOptions'),
    },
    {
      target: '.matchOddsContainer',
      content: t('matchDetails.onboarding.clickToBet'),
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  if (!matchDetails) {
    return null;
  }

  const renderModalBet = (
    odds,
    name,
    handicap,
    header,
    formattedKey,
    subkey,
    betKey
  ) => {
    header =
      header === '1' ? betTeamHome : header === '2' ? betTeamAway : header;

    // First dispatch all bet details
    dispatch(betMatch(`${betTeamHome} - ${betTeamAway}`));
    dispatch(betOdd(odds));
    dispatch(betName(formattedKey));
    dispatch(betHandicap(handicap));
    dispatch(betSigne(name));
    dispatch(betSigneSelected(header));
    dispatch(betType(subkey));
    dispatch(setBetKey(betKey));

    // Automatically add the bet
    dispatch(addSelectedBet());

    setIsModalOpen(true);
  };

  const renderBetOption = (bet, sectionName, betKey) => (
    <div
      key={bet.id}
      className={styles.matchOddsContainer}
      onClick={() =>
        renderModalBet(
          bet.odds,
          bet.name,
          bet.handicap,
          bet.header,
          sectionName,
          sectionName,
          betKey,
          betTeamHome,
          betTeamAway
        )
      }
    >
      <p className={styles.odds}>{bet.odds}</p>
      {bet.name && (
        <p className={styles.betName}>
          {bet.name === '1'
            ? betTeamHome
            : bet.name === '2'
              ? betTeamAway
              : bet.name}
        </p>
      )}
      {bet.handicap && <p className={styles.handicap}>{bet.handicap}</p>}
      {bet.header && (
        <p className={styles.betHeader}>
          {bet.header === 'teamhome'
            ? betTeamHome
            : bet.header === 'teamaway'
              ? betTeamAway
              : bet.header}
        </p>
      )}
    </div>
  );

  const mainOddsKeys = {
    full_time_result: 'Match Winner',
    double_chance: 'Double Chance',
    draw_no_bet: 'Draw No Bet',
    half_time_result: 'Half Time Result',
    half_time_full_time: 'Half Time/Full Time',
    goals_over_under: 'Goals Over/Under',
    both_teams_to_score: 'Both Teams to Score',
    alternative_handicap_result: 'Alternative Handicap',
    team_totals: 'Team Totals',
    asian_handicap: 'Asian Handicap',
    goal_line: 'Goal Line',
    result_total_goals: 'Result & Total Goals',
    total_goals_both_teams_to_score: 'Total Goals Both Teams to Score',
    exact_total_goals: 'Exact Total Goals',
    goals_odd_even: 'Goals Odd/Even',
    main_props: 'Main Props',
    half_props: 'Half Props',
  };

  const renderSection = (title, bets, key) => {
    // For main_props and half_props, we need to handle nested objects
    if (key === 'main_props' || key === 'half_props') {
      if (!bets || Object.keys(bets).length === 0) return null;

      return Object.entries(bets).map(([subKey, subBets]) => {
        if (!subBets || subBets.length === 0) return null;

        return (
          <div key={subKey} className={styles.betGroup}>
            <h5 className={styles.betSubtypeTitle}>{formatBetTitle(subKey)}</h5>
            <div className={styles.betRow}>
              {subBets.map(bet =>
                renderBetOption(bet, formatBetTitle(subKey), subKey)
              )}
            </div>
          </div>
        );
      });
    }

    // For regular bet types
    if (!bets || bets.length === 0) return null;

    return (
      <div key={key} className={styles.betGroup}>
        <h5 className={styles.betSubtypeTitle}>{title}</h5>
        <div className={styles.betRow}>
          {bets.map(bet => renderBetOption(bet, title, key))}
        </div>
      </div>
    );
  };

  const formatBetTitle = key => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleBackToList = () => {
    setShowMatchDetails(false);
  };

  return (
    <div
      className={`${styles.matchDetails} col-12 col-md-12 row ${isModalOpen ? styles.blur : ''}`}
      style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: '84vh'}}
    >
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        stepIndex={onboardingStep}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#007bff',
          },
        }}
        debug={true}
      />

      {isMobile && (
        <Button onClick={handleBackToList} className={styles.backToListButton}>
          {t('matchDetails.backToList')}
        </Button>
      )}

      <div className={styles.matchDetailsContainer}>
        {Object.entries(mainOddsKeys).map(([key, title]) => {
          const bets = matchDetails[key];
          return renderSection(title, bets, key);
        })}
      </div>
    </div>
  );
};

MatchDetailsBasket.propTypes = {
  matchDetails: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  showMatchDetails: PropTypes.bool.isRequired,
  setShowMatchDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MatchDetailsBasket;
