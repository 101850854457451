import {useState, useContext, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../context/auth.context';
import {gameMode} from '../redux/betSlice';
import {userEntryPass, setIsNewUser, resetOnboarding} from '../redux/userSlice';
import {useTranslation} from 'react-i18next';
import {
  FaHome,
  FaTrophy,
  FaDice,
  FaInfoCircle,
  FaUser,
  FaSignOutAlt,
  FaTimes,
  FaWallet,
} from 'react-icons/fa';
import styles from './navbar.module.css';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const navRef = useRef(null);
  const {logOutUser} = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {userMoney, photoProfile, userName} = useSelector(state => state.user);

  const menuItems = [
    {
      icon: <FaHome />,
      text: t('navbar.home'),
      path: '/home',
    },
    {
      icon: <FaTrophy />,
      text: t('navbar.leagues'),
      submenu: [
        {text: t('navbar.viewAllLeagues'), path: '/home/leagues/all-leagues'},
        {text: t('navbar.myLeagues'), path: '/home/leagues/my-leagues'},
        {text: t('navbar.createLeague'), path: '/home/leagues/create-league'},
      ],
    },
    {
      icon: <FaDice />,
      text: t('navbar.doubles'),
      submenu: [
        {text: t('navbar.viewAllDoubles'), path: '/home/doubles/all-doubles'},
        {text: t('navbar.myDoubles'), path: '/home/doubles/my-doubles'},
        {text: t('navbar.createDouble'), path: '/home/doubles/create-double'},
      ],
    },
    {
      icon: <FaInfoCircle />,
      text: t('navbar.aboutUs'),
      path: '/home/about-us',
    },
  ];

  const handleNavigation = (path, mode) => {
    if (mode) dispatch(gameMode(mode));
    history.push(path);
    if (window.innerWidth <= 768) setExpanded(false);
  };

  const handleSubmenu = index => {
    setOpenSubmenu(openSubmenu === index ? null : index);
    setExpanded(true);
  };

  const handleLogout = () => {
    logOutUser();
    dispatch(setIsNewUser(false));
    dispatch(userEntryPass(false));
    dispatch(resetOnboarding());
    history.push('/');
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        if (!event.target.classList.contains(styles.mobileToggle)) {
          setExpanded(false);
          setOpenSubmenu(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        className={styles.hamburgerButton}
        onClick={() => setExpanded(!expanded)}
        aria-label="Menu"
      >
        {expanded ? (
          <FaTimes style={{color: '#fff', width: '30px', height: '30px'}} />
        ) : (
          <div
            className={`${styles.hamburgerIcon} ${expanded ? styles.open : ''}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </button>

      <nav
        ref={navRef}
        className={`${styles.principalNav} ${expanded ? styles.expanded : ''}`}
      >
        <div className={styles.navContainer}>
          {menuItems.map((item, index) => (
            <div key={index}>
              <div
                className={styles.navItem}
                onClick={() =>
                  item.submenu
                    ? handleSubmenu(index)
                    : handleNavigation(item.path)
                }
              >
                <span className={styles.navIcon}>{item.icon}</span>
                <span className={styles.navText}>{item.text}</span>
              </div>
              {item.submenu && (
                <div
                  className={`${styles.subMenu} ${openSubmenu === index ? styles.open : ''}`}
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className={styles.subMenuItem}
                      onClick={() => handleNavigation(subItem.path)}
                    >
                      {subItem.text}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className={styles.userSection}>
            {userName ? (
              <>
                <div className={styles.userInfo}>
                  <img
                    src={photoProfile}
                    alt="profile"
                    className={styles.userAvatar}
                  />
                  <span className={styles.navText}>{userName}</span>
                </div>
                <div
                  className={styles.navItem}
                  onClick={() => handleNavigation('/home/deposit')}
                >
                  <span className={styles.navIcon}>
                    <FaWallet />
                  </span>
                  <span className={styles.navText}>
                    {userMoney.toFixed(2)}€
                  </span>
                </div>
                <div
                  className={styles.navItem}
                  onClick={() => handleNavigation('/home/profile')}
                >
                  <span className={styles.navIcon}>
                    <FaUser />
                  </span>
                  <span className={styles.navText}>{t('navbar.profile')}</span>
                </div>
                <div className={styles.navItem} onClick={handleLogout}>
                  <span className={styles.navIcon}>
                    <FaSignOutAlt />
                  </span>
                  <span className={styles.navText}>{t('navbar.logout')}</span>
                </div>
              </>
            ) : (
              <div
                className={styles.navItem}
                onClick={() => handleNavigation('/login')}
              >
                <span className={styles.navIcon}>
                  <FaUser />
                </span>
                <span className={styles.navText}>{t('navbar.login')}</span>
              </div>
            )}
          </div>
        </div>
      </nav>
      <div
        className={`${styles.mainContent} ${expanded ? styles.shifted : ''}`}
      >
        {/* Your main content goes here */}
      </div>
    </>
  );
};

export default Navbar;
