import {useContext, useState, useCallback, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {AuthContext} from '../../context/auth.context';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useSelector, useDispatch} from 'react-redux';
import {
  setGameType,
  setGameId,
  setGameName,
  setCanBet,
} from '../../redux/actualGameSlice';
import {setUserMoney} from '../../redux/userSlice';
import {useTranslation} from 'react-i18next';
import styles from './createDouble.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const initialFormData = {
  name: '',
  inscriptionPrice: '',
  accessCode: '',
  photo: null,
  finishDate: '',
};

const CreateDouble = () => {
  const {isLoggedIn} = useContext(AuthContext);
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const history = useHistory();
  const {userMoney, userId} = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => setError(null), 3000);
    return () => clearTimeout(timeout);
  }, [error]);

  const handleInputChange = useCallback(
    e => {
      const {name, value} = e.target;
      setFormData(prevData => ({...prevData, [name]: value}));
      if (name === 'inscriptionPrice' && userMoney < value) {
        setError(t('createDouble.insufficientFunds'));
      }
    },
    [userMoney, t]
  );

  const handleImageUpload = useCallback(
    async e => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'betandfriends');

      try {
        const response = await axios.post(
          'https://api.cloudinary.com/v1_1/dpkplya7n/image/upload',
          formData
        );
        setImageUrl(response.data.secure_url);
      } catch (error) {
        console.error('Error uploading image:', error);
        setError(t('createDouble.errorUploadingImage'));
      }
    },
    [t]
  );

  const validateForm = useCallback(() => {
    if (formData.name.trim() === '') {
      setError(t('createDouble.nameFieldEmpty'));
      return false;
    }
    if (userMoney < formData.inscriptionPrice) {
      setError(t('createDouble.insufficientFunds'));
      return false;
    }
    if (formData.finishDate === '') {
      setError(t('createDouble.errorDuration'));
      return false;
    }
    return true;
  }, [
    formData.name,
    formData.inscriptionPrice,
    formData.finishDate,
    userMoney,
    t,
  ]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (!isLoggedIn) {
        setError('You must be logged in to create a double.');
        return;
      }
      if (!validateForm()) return;

      const actualDateEpoch = Date.now() / 1000;

      const newDouble = {
        userId,
        ...formData,
        finishDate: parseInt(formData.finishDate) * 86400 + actualDateEpoch,
        photo:
          imageUrl ||
          'https://res.cloudinary.com/dpkplya7n/image/upload/v1699700824/BetandfriendsProfile/a2mcacenxvw3zm7o1huy.png',
      };

      try {
        const double = await axios.post(`${API_URL}/newdouble`, newDouble);
        const newUserMoney = userMoney - formData.inscriptionPrice;
        dispatch(setUserMoney(newUserMoney));
        dispatch(setGameName(double.data.name));
        dispatch(setGameId(double.data._id));
        dispatch(setGameType('doubles'));
        dispatch(setCanBet(true));
        setFormData(initialFormData);
        setImageUrl('');
        history.push(`/home/doubles/${double.data._id}/${double.data.name}`);
      } catch (error) {
        setError(t('createDouble.errorCreating'));
        console.error('Error creating double:', error);
      }
    },
    [
      isLoggedIn,
      userId,
      formData,
      userMoney,
      imageUrl,
      history,
      dispatch,
      validateForm,
      t,
    ]
  );

  if (!isLoggedIn) {
    return (
      <>
        <Navigation />
        <div className={styles.loginPrompt}>
          <h4>
            {t('createDouble.loginToCreate')}{' '}
            <Link to="/login">{t('common.login')}</Link> {t('common.or')}{' '}
            <Link to="/signup">{t('common.signup')}</Link>
          </h4>
        </div>
      </>
    );
  }

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <h3 className={styles.title}>{t('createDouble.title')}</h3>
        <h5 className={styles.description}>{t('createDouble.description')}</h5>
        <h6 className={styles.description}>
          {t('createDouble.additionalInfo')}
        </h6>

        {error && <p className={styles.errorMessage}>{error}</p>}

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder={t('createDouble.doubleName')}
            />
          </div>

          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="number"
              name="inscriptionPrice"
              value={formData.inscriptionPrice}
              onChange={handleInputChange}
              placeholder={t('createDouble.inscriptionCost')}
            />
          </div>

          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="text"
              name="accessCode"
              value={formData.accessCode}
              onChange={handleInputChange}
              placeholder={t('createDouble.accessCode')}
            />
          </div>

          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="number"
              name="finishDate"
              value={formData.finishDate}
              onChange={handleInputChange}
              placeholder={t('createDouble.duration')}
            />
          </div>

          <div className={styles.inputGroup}>
            <input
              className={styles.fileInput}
              type="file"
              name="photo"
              onChange={handleImageUpload}
              accept="image/*"
            />
          </div>

          {imageUrl && (
            <img src={imageUrl} alt="Double" className={styles.previewImage} />
          )}

          <button className={styles.btn} type="submit">
            {t('createDouble.createButton')}
          </button>
        </form>
      </div>
    </>
  );
};

export default CreateDouble;
