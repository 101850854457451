import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: null,
  nameLeague: null,
  coinsInLeague: null,
  coinsInPlay: null,
  openBets: 0,
};

export const userInLeagueSlice = createSlice({
  name: "userINLeague",
  initialState,
  reducers: {
    userInLeagueSliceSuccess: (state, action) => {
      state.userName = action.payload;
    },
    userInLeagueName: (state, action) => {
      state.nameLeague = action.payload;
    },
    userInLeagueCoins: (state, action) => {
      state.coinsInLeague = action.payload;
    },
    updateCoins: (state, action) => {
      state.coinsInLeague = action.payload;
    },
    updateEurosPlayed: (state, action) => {
      state.currentUser.eurosplayed = action.payload;
    },
    totalOpenBets: (state, action) => {
      state.openBets = action.payload;
    },
  },
});

export const {
  userInLeagueSliceSuccess,
  userInLeagueCoins,
  subscription,
  updateCoins,
  updateOpenLeagues,
  updateEurosPlayed,
  userInLeagueName,
  totalOpenBets,
} = userInLeagueSlice.actions;

export default userInLeagueSlice.reducer;
