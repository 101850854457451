import PropTypes from 'prop-types';
import {useContext, useCallback} from 'react';
import axios from 'axios';
import {useHistory, Link} from 'react-router-dom';
import {AuthContext} from '../../context/auth.context';
import {useDispatch, useSelector} from 'react-redux';
import LeagueClassification from '../../components/LeagueClassification';
import Navigation from '../../components/Navbar';
import {useLeagueDetails} from '../../hooks/useLeagueDetails';
import {useCoinsInLeagueUsers} from '../../hooks/useCoinsInLeagueUsers';
import {formatDecimal} from '../../helpers/formatDecimal';
import {setUserMoney} from '../../redux/userSlice';
import {updateOpenLeagues} from '../../redux/leagueSlice';
import {useTranslation} from 'react-i18next';
import styles from './seeLeagues.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const SeeLeagues = ({match}) => {
  const {t} = useTranslation();
  const {user, isLoggedIn} = useContext(AuthContext);
  const {userId, userMoney} = useSelector(state => state.user);

  const dispatch = useDispatch();
  const leagueId = match.params.id;
  const history = useHistory();
  const game = 'league';

  const {leagueInfo, loading} = useLeagueDetails(leagueId, isLoggedIn);
  const {usersCoinsInfo, loading2} = useCoinsInLeagueUsers(
    leagueId,
    isLoggedIn,
    userId
  );

  const handleJoinLeague = useCallback(
    async e => {
      e.preventDefault();

      try {
        await axios.post(`${API_URL}/join-league`, {
          userId: user._id,
          leagueId: leagueInfo._id,
          numberCoinsInLeague: leagueInfo.numberCoinsInLeague,
          inscriptionPrice: leagueInfo.inscriptionPrice,
        });

        dispatch(setUserMoney(userMoney - leagueInfo.inscriptionPrice));
        dispatch(updateOpenLeagues(leagueInfo));
        history.push(
          `/home/leagues/${leagueInfo._id}/${leagueInfo.nameLeague}`
        );
      } catch (error) {
        console.error('Error joining league:', error);
      }
    },
    [leagueInfo, userMoney, user?._id, history, dispatch]
  );

  if (loading || loading2) {
    return (
      <>
        <Navigation />
        <div className={styles.loading}>{t('common.loading')}</div>
      </>
    );
  }

  const canJoinLeague =
    !leagueInfo.participants.includes(user._id) &&
    leagueInfo.participants.length < leagueInfo.maxParticipants;

  return (
    <>
      <Navigation />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.leagueInfo}>
            <h2 className={styles.leagueTitle}>
              {t('seeLeagues.welcome')} {leagueInfo?.nameLeague}
            </h2>

            <div className={styles.infoCard}>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>
                  {t('seeLeagues.playingFor')}
                </span>
                <span className={styles.infoValue}>
                  {formatDecimal(leagueInfo.potToWinners)}€
                </span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>
                  {t('leagueDetails.potDistribution')}
                </span>
                <span className={styles.infoValue}>
                  {t(
                    `leagueDetails.distributions.${leagueInfo.potDistribution}`
                  )}
                </span>
              </div>
            </div>

            {canJoinLeague ? (
              <button className={styles.joinButton} onClick={handleJoinLeague}>
                {t('seeLeagues.joinLeague')}
              </button>
            ) : (
              <p className={styles.alert}>{t('seeLeagues.alreadyMember')}</p>
            )}

            <Link to="/home/leagues/all-leagues">
              <button className={styles.joinButton}>
                {t('seeLeagues.seeAllLeagues')}
              </button>
            </Link>
          </div>

          <LeagueClassification
            usersCoinsInfo={usersCoinsInfo}
            leagueId={leagueId}
            game={game}
            seeLeague={true}
          />
        </div>
      </div>
    </>
  );
};

SeeLeagues.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SeeLeagues;
