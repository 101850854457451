// useLeagues.js
import {useState, useEffect, useCallback} from 'react';
import axios from 'axios';

const useLeagues = API_URL => {
  const [leagues, setLeagues] = useState([]);
  const [leaguesSearch, setLeaguesSearch] = useState([]);

  const getAllLeagues = useCallback(() => {
    axios
      .get(`${API_URL}/all-leagues`)
      .then(response => {
        setLeagues(response.data);
        setLeaguesSearch(response.data);
      })
      .catch(error => console.log(error));
  }, [API_URL]);

  useEffect(() => {
    getAllLeagues();
  }, [getAllLeagues]);

  const filterLeagues = useCallback(
    search => {
      if (search === '') {
        setLeagues(leaguesSearch);
      } else {
        const searchResult = leaguesSearch.filter(league =>
          league.nameLeague.toLowerCase().includes(search.toLowerCase())
        );
        setLeagues(searchResult);
      }
    },
    [leaguesSearch]
  );

  const handleLeagueSearch = useCallback(
    e => {
      filterLeagues(e.target.value);
    },
    [filterLeagues]
  );

  const applyFilters = useCallback(
    async (filterType, filterOrder) => {
      try {
        const response = await axios.post(`${API_URL}/filter-leagues`, {
          filterType,
          filterOrder,
        });
        setLeagues(response.data);
      } catch (error) {
        console.error('Error applying filters:', error);
      }
    },
    [API_URL]
  );

  return {
    leagues,
    leaguesSearch,
    handleLeagueSearch,
    filterLeagues,
    setLeagues,
    applyFilters,
  };
};

export default useLeagues;
