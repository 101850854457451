import {useContext, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../context/auth.context';
import Joyride, {STATUS} from 'react-joyride';
import {useTranslation} from 'react-i18next';
import liga from './assets/ligas.png';
import nuevaLiga from './assets/nuevaLiga.png';
import doubles from './assets/doubles.png';
import qa from './assets/qa.jpg';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import Card from '../../components/card/Card';
import styles from './homePage.module.css';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import {incrementOnboardingStep, resetOnboarding} from '../../redux/userSlice';
import {useSelector, useDispatch} from 'react-redux';

const HomePage = () => {
  const {isLoggedIn} = useContext(AuthContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);
  const isNewUser = useSelector(state => state.user.isNewUser);

  useEffect(() => {
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser]);

  const cardData = [
    {
      imageSrc: nuevaLiga,
      altText: t('homePage.leagues.title'),
      title: t('homePage.leagues.title'),
      description: t('homePage.leagues.description'),
      link: '/home/leagues',
      source: 'Modes',
      btnText: t('homePage.leagues.button'),
    },
    {
      imageSrc: doubles,
      altText: t('homePage.doubles.title'),
      title: t('homePage.doubles.title'),
      description: t('homePage.doubles.description'),
      link: '/home/doubles',
      source: 'Modes',
      btnText: t('homePage.doubles.button'),
    },
  ];
  const steps = [
    {
      target: '#contentTitle',
      content: t('homePage.onboarding.welcome'),
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '#cardContainer',
      content: t('homePage.onboarding.gameModes'),
      placement: 'bottom',
    },
    {
      target: `[data-testid="league-card"]`,
      content: t('homePage.onboarding.leagueMode'),
      placement: 'right',
    },
    {
      target: `[data-testid="double-card"]`,
      content: t('homePage.onboarding.doubleMode'),
      placement: 'left',
    },
    {
      target: `[data-testid="league-card"] button`,
      content: t('homePage.onboarding.clickEnterLeague'),
      placement: 'bottom',
      spotlightClicks: true,
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  const handleScrollClick = useCallback(() => {
    document.getElementById('ctaSection')?.scrollIntoView({behavior: 'smooth'});
  }, []);
  const renderLoggedInContent = () => (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <main className={styles.main}>
          <div className={styles.contentWrapper}>
            <div id="contentTitle" className={styles.contentTitle}>
              <h1>
                {t('homePage.chooseGameMode')}
                {t('homePage.chooseGameMode2part')}
                {t('homePage.chooseGameMode3part')}
              </h1>
            </div>
            <div id="cardContainer" className={styles.cardContainer}>
              {cardData.map((card, index) => (
                <Card
                  key={index}
                  {...card}
                  data-testid={index === 0 ? 'league-card' : 'double-card'}
                />
              ))}
            </div>
          </div>
        </main>
      </div>
    </>
  );
  const renderLoggedOutContent = () => (
    <div className={styles.App}>
      <div className={styles.languageSwitcherContainer}>
        <Link to="/qa">
          <img src={qa} alt="Q&A" className={styles.qaIcon} />
        </Link>
        <LanguageSwitcher />
      </div>
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <p>{t('homePage.hero.description')}</p>
        </div>
        <div className={styles.scrollIndicator} onClick={handleScrollClick}>
          <span>scroll</span>
          <div className={styles.scrollLine}></div>
        </div>
      </section>
      <section className={styles.cta} id="ctaSection">
        <div className={styles.ctaContent}>
          <h1>{t('homePage.cta.title')}</h1>
          <h3>{t('homePage.cta.startPlaying')}</h3>
          <div className={styles.ctaForm}>
            <Link to="/signup">
              <button className={styles.ctaButton}>
                {t('homePage.cta.createAccount')}
              </button>
            </Link>
            <p>{t('homePage.cta.termsAgreement')}</p>
            <h3>{t('homePage.cta.alreadyHaveAccount')}</h3>
            <Link to="/login">
              <button className={styles.loginButton}>
                {t('homePage.cta.login')}
              </button>
            </Link>
          </div>
        </div>
        <div className={styles.ctaImage}>
          <img className={styles.image} src={liga} alt="Celebrating team" />
        </div>
      </section>
    </div>
  );

  return (
    <>
      <div>
        {isLoggedIn ? renderLoggedInContent() : renderLoggedOutContent()}
      </div>
      <div>
        {isLoggedIn && (
          <Joyride
            steps={steps}
            run={runTour}
            continuous={true}
            showSkipButton={true}
            showProgress={true}
            stepIndex={onboardingStep}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                primaryColor: '#007bff',
              },
            }}
            debug={true} // Enable debug mode
          />
        )}
      </div>
    </>
  );
};

export default HomePage;
