import PropTypes from 'prop-types';
import {useContext} from 'react';
import {AuthContext} from '../context/auth.context';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';

function PrivateRoute({to, exact, component: Component, ...restProps}) {
  const {userEntryPass} = useSelector(state => state.user);
  const {isLoggedIn, isLoading} = useContext(AuthContext);

  // If the authentication is still loading ⏳
  if (isLoading) return <p>Loading ...</p>;

  // If the user is not logged in ❌
  if (!isLoggedIn && !userEntryPass) return <Redirect to="/" />;

  // If the user is logged in ✅
  return <Route to={to} exact={exact} component={Component} {...restProps} />;
}

PrivateRoute.propTypes = {
  to: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
