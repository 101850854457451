import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  betMatchId: '',
  betMatch: '',
  betTeamHome: '',
  betTeamAway: '',
  betSigne: '',
  betSigneSelected: '',
  betOdd: '',
  betName: '',
  betHandicap: '',
  betLeagueId: '',
  betLeagueName: '',
  matchTime: '',
  matchId: '',
  coinsInLeague: '',
  gameMode: '',
  betType: '',
  betSport: '',
  betKey: '',
  selectedBets: [],
  isCombinationBet: false,
};

export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    betMatch: (state, action) => {
      state.betMatch = action.payload;
    },
    betTeamHome: (state, action) => {
      state.betTeamHome = action.payload;
    },
    betTeamAway: (state, action) => {
      state.betTeamAway = action.payload;
    },
    betSigne: (state, action) => {
      state.betSigne = action.payload;
    },
    betType: (state, action) => {
      state.betType = action.payload;
    },
    setBetKey: (state, action) => {
      state.betKey = action.payload;
    },
    betOdd: (state, action) => {
      state.betOdd = action.payload;
    },
    betName: (state, action) => {
      state.betName = action.payload;
    },
    betHandicap: (state, action) => {
      state.betHandicap = action.payload;
    },
    betLeagueName: (state, action) => {
      state.betLeagueName = action.payload;
    },
    betSigneSelected: (state, action) => {
      state.betSigneSelected = action.payload;
    },
    betSport: (state, action) => {
      state.betSport = action.payload;
    },
    betLeagueId: (state, action) => {
      state.betLeagueId = action.payload;
    },
    matchTime: (state, action) => {
      state.matchTime = action.payload;
    },
    matchId: (state, action) => {
      state.matchId = action.payload;
    },
    setCoinsInLeague: (state, action) => {
      state.coinsInLeague = action.payload;
    },
    gameMode: (state, action) => {
      state.gameMode = action.payload;
    },
    addSelectedBet: state => {
      if (!state.selectedBets) {
        state.selectedBets = [];
      }

      const isMatchRepeated = state.selectedBets.some(
        bet => bet.matchId === state.matchId
      );

      const newBet = {
        betMatch: state.betMatch,
        betTeamHome: state.betTeamHome,
        betTeamAway: state.betTeamAway,
        betSigne: state.betSigne,
        betSigneSelected: state.betSigneSelected,
        betOdd: state.betOdd,
        betName: state.betName,
        betHandicap: state.betHandicap,
        matchTime: state.matchTime,
        matchId: state.matchId,
        betType: state.betType,
        betKey: state.betKey,
        isRepeated: isMatchRepeated,
        betSport: state.betSport,
      };
      state.selectedBets.push(newBet);
    },
    removeSelectedBet: (state, action) => {
      if (!state.selectedBets) {
        state.selectedBets = [];
        return;
      }

      const removedBet = state.selectedBets[action.payload];

      if (removedBet && !removedBet.isRepeated) {
        const repeatedBetIndex = state.selectedBets.findIndex(
          bet => bet.isRepeated && bet.matchId === removedBet.matchId
        );

        if (repeatedBetIndex !== -1) {
          state.selectedBets[repeatedBetIndex].isRepeated = false;
        }
      }

      state.selectedBets = state.selectedBets.filter(
        (_, index) => index !== action.payload
      );
    },
    clearSelectedBets: state => {
      state.selectedBets = [];
    },
    setIsCombinationBet: (state, action) => {
      state.isCombinationBet = action.payload;
    },
  },
});

export const {
  betMatch,
  betTeamHome,
  betTeamAway,
  betType,
  betSigne,
  betOdd,
  betName,
  betSport,
  betHandicap,
  betSigneSelected,
  betLeagueId,
  betLeagueName,
  matchTime,
  matchId,
  setCoinsInLeague,
  setBetKey,
  gameMode,
  addSelectedBet,
  removeSelectedBet,
  clearSelectedBets,
  setIsCombinationBet,
} = betSlice.actions;

export default betSlice.reducer;
