import {useState, useEffect} from 'react';
import axios from 'axios';

export const useUserInLeagueCoins = (userId, leagueId, isLoggedIn) => {
  const [userInLeagueCoins, setUserInLeagueCoins] = useState({});

  useEffect(() => {
    if (isLoggedIn) {
      const coinsInLeagueUserCoins = {
        userId,
        leagueId,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/get-userinleague`,
          coinsInLeagueUserCoins
        )
        .then(response => {
          setUserInLeagueCoins(response.data[0] || {});
        });
    }
  }, [isLoggedIn, userId, leagueId]);

  return {userInLeagueCoins};
};
