import Breadcrumbs from './Breadcrumbs';
import LanguageSwitcher from './LanguageSwitcher';
import styles from './BreadAndLanguage.module.css';

const BreadAndLanguage = () => {
  return (
    <div className={styles.BreadAndLanguageWrapper}>
      <div className={styles.leftSection}>
        <Breadcrumbs />
      </div>
      <div className={styles.rightSection}>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default BreadAndLanguage;
