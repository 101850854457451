import {useState, useEffect} from 'react';
import axios from 'axios';

export const useLeagueDetails = (leagueId, isLoggedIn) => {
  const [leagueInfo, setLeagueInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/league-details/${leagueId}`)
        .then(response => {
          setLeagueInfo(response.data[0]);
          setLoading(false);
        });
    }
  }, [isLoggedIn, leagueId]);

  return {leagueInfo, loading};
};
