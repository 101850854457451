import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './LanguageSwitcher.module.css';

// Import flag images
import spanishFlag from '../assets/spain.png';
import englishFlag from '../assets/english.png';
import frenchFlag from '../assets/french.png';
import germanyFlag from '../assets/germany.png';
import italyFlag from '../assets/italy.png';

const LanguageSwitcher = () => {
  const {i18n} = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const languageSwitcherRef = useRef(null);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        languageSwitcherRef.current &&
        !languageSwitcherRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const flags = {
    es: spanishFlag,
    en: englishFlag,
    fr: frenchFlag,
    de: germanyFlag,
    it: italyFlag,
  };

  const currentFlag = flags[i18n.language] || englishFlag;

  return (
    <div className={styles.languageSwitcher} ref={languageSwitcherRef}>
      <button
        className={`${styles.languageButton} ${styles.active}`}
        onClick={toggleOptions}
      >
        <img
          src={currentFlag}
          alt="Current Language Flag"
          className={styles.flag}
        />
      </button>
      {showOptions && (
        <div className={styles.optionsContainer}>
          {Object.entries(flags).map(([lang, flag]) => {
            if (lang !== i18n.language) {
              return (
                <button
                  key={lang}
                  className={styles.languageButton}
                  onClick={() => changeLanguage(lang)}
                >
                  <img
                    src={flag}
                    alt={`${lang} Flag`}
                    className={styles.flag}
                  />
                </button>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
