import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {persistor, store} from './redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {AuthProviderWrapper} from './context/auth.context';
import {I18nextProvider} from 'react-i18next';
import i18n from './locales';

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router>
        <AuthProviderWrapper>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </AuthProviderWrapper>
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);
reportWebVitals();
