import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import styles from './subNavbar.module.css';

const SubNavbar = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const {coinsInLeague} = useSelector(state => state.bet);
  const {gameName, gameId, gameType, canBet} = useSelector(
    state => state.actualGame
  );

  const handleNavLinkClick = useCallback(
    path => () => {
      history.push(path);
    },
    [history]
  );

  const isLeague = gameType === 'leagues';
  const isDouble = gameType === 'doubles';

  const getBetsToDoText = useCallback(() => {
    if (isDouble && canBet) {
      return t('subNavbar.betsToDo') + '1';
    }
    return t('subNavbar.betsToDo') + '0';
  }, [isDouble, canBet, t]);

  const renderNavLinks = () => (
    <div className={styles.navLinks}>
      <span
        className={styles.navLink}
        onClick={handleNavLinkClick(`/home/${gameType}/${gameId}/${gameName}`)}
        role="button"
        tabIndex={0}
      >
        {t('subNavbar.home')}
      </span>
      {isLeague && (
        <span
          className={styles.navLink}
          onClick={handleNavLinkClick(
            `/home/${gameType}/${gameId}/${gameName}/my-bets`
          )}
          role="button"
          tabIndex={0}
        >
          {t('subNavbar.myBets')}
        </span>
      )}
      {(isLeague || (isDouble && canBet)) && (
        <span
          className={styles.navLink}
          onClick={handleNavLinkClick(
            `/home/${gameType}/${gameId}/${gameName}/competitions`
          )}
          role="button"
          tabIndex={0}
        >
          {t('subNavbar.bet')}
        </span>
      )}
    </div>
  );

  const renderNavInfo = () => (
    <div className={styles.navInfo}>
      <span className={styles.gameName}>{gameName}</span>
      {isLeague && (
        <div className={styles.coinsInfo}>
          <span>
            {t('subNavbar.coins')}: {coinsInLeague}
          </span>
        </div>
      )}
      {isDouble && <span>{getBetsToDoText()}</span>}
    </div>
  );

  return (
    <nav className={styles.subNavbar}>
      <Container className={styles.container}>
        {renderNavLinks()}
        {renderNavInfo()}
      </Container>
    </nav>
  );
};

SubNavbar.propTypes = {
  id: PropTypes.string,
  game: PropTypes.string,
  leagueName: PropTypes.string,
};

export default SubNavbar;
