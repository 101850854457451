import {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthContext} from '../context/auth.context';

function AnonRoute(props) {
  const {isLoggedIn, isLoading} = useContext(AuthContext);

  if (isLoading) return <p>Loading ...</p>;

  if (isLoggedIn) return <Redirect to="/home" />;

  return <Route {...props} />;
}

export default AnonRoute;
