import {useState, useContext, useCallback} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import {login} from '../../redux/actions/authActions';
import {AuthContext} from '../../context/auth.context';
import liga from '../HomePage/assets/ligas.png';
import qa from '../HomePage/assets/qa.jpg';
import styles from './loginPage.module.css';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const initialState = {
  email: '',
  password: '',
  errorMessage: undefined,
  inputType: 'password',
  icon: <FaEye />,
  resetEmail: '',
  resetMessage: '',
};

const LoginPage = () => {
  const {t} = useTranslation();
  const [state, setState] = useState(initialState);
  const {logInUser} = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = useCallback(e => {
    const {name, value} = e.target;
    setState(prevState => ({...prevState, [name]: value}));
  }, []);

  const handleScrollClick = useCallback(() => {
    document.getElementById('ctaSection')?.scrollIntoView({behavior: 'smooth'});
  }, []);

  const handleLoginSubmit = useCallback(
    async e => {
      e.preventDefault();
      const {email, password} = state;

      try {
        const {authToken, user} = await dispatch(login({email, password}));
        logInUser({token: authToken, user});

        const isUserAdmin = user.isAdmin;

        if (isUserAdmin) {
          history.push('/admin');
        } else {
          history.push('/home');
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          errorMessage: error.response?.data?.message || 'An error occurred',
        }));
      }
    },
    [state, dispatch, history, logInUser]
  );

  const togglePasswordVisibility = e => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const {email, password, errorMessage} = state;

  return (
    <div className={styles.App}>
      <div className={styles.languageSwitcherContainer}>
        <Link to="/qa">
          <img src={qa} alt="Q&A" className={styles.qaIcon} />
        </Link>
        <LanguageSwitcher />
      </div>
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <p>{t('homePage.hero.description')}</p>
        </div>
        <div className={styles.scrollIndicator} onClick={handleScrollClick}>
          <span>scroll</span>
          <div className={styles.scrollLine}></div>
        </div>
      </section>
      <section className={styles.cta} id="ctaSection">
        <div className={styles.ctaContent}>
          <h1>{t('login.form.title')}</h1>
          <h3>{t('login.form.subtitle')}</h3>
          <form onSubmit={handleLoginSubmit}>
            <div className={styles.ctaForm}>
              <input
                className={styles.ctaButton}
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder={t('login.form.email')}
                required
              />
            </div>
            <div className={styles.ctaForm}>
              <div className={styles.passwordContainer}>
                <input
                  className={styles.ctaButton}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder={t('login.form.password')}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className={styles.passwordIcon}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <button type="submit" className={styles.loginButton}>
              {t('login.form.submitButton')}
            </button>
          </form>
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          <p>
            {t('login.form.noAccount')}{' '}
            <Link to="/signup">{t('login.form.createAccount')}</Link>
          </p>
          <p>
            {t('login.form.forgotPassword')}{' '}
            <Link to="/recover-password">{t('login.form.recoverAccount')}</Link>
          </p>
        </div>
        <div className={styles.ctaImage}>
          <img className={styles.image} src={liga} alt="Celebrating team" />
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
