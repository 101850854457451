import {Link} from 'react-router-dom';
import styles from './AdminHome.module.css';

const AdminHome = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Admin Panel</h1>
      <div className={styles.buttonContainer}>
        <Link to="/admin/dashboard" className={styles.button}>
          Dashboard
        </Link>
        <Link to="/admin/promotions" className={styles.button}>
          Promotions
        </Link>
        <Link to="/admin/pending-withdrawals" className={styles.button}>
          Pending Withdrawals
        </Link>
        <Link to="/admin/messages" className={styles.button}>
          Messages
        </Link>
      </div>
    </div>
  );
};

export default AdminHome;
