import {useState, useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styles from './Promotions.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const Promotions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [amount, setAmount] = useState('');
  const [rollOver, setRollOver] = useState('');
  const [message, setMessage] = useState('');
  const {token} = useSelector(state => state.user);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/users`, {
          headers: {Authorization: `Bearer ${token}`},
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage('Error fetching users');
      }
    };

    fetchUsers();
  }, [token]);

  const handleUserSelect = userId => {
    setSelectedUsers(prev =>
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/add`,
        {
          userIds: selectedUsers,
          amount: parseFloat(amount),
          rollOver: parseFloat(rollOver),
        },
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      setMessage(response.data.message);
      setSelectedUsers([]);
      setAmount('');
      setRollOver('');
    } catch (error) {
      console.error('Error adding promotion:', error);
      setMessage('Error adding promotion');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Add Promotions</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label htmlFor="amount">Amount:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="rollOver">Roll Over:</label>
          <input
            type="number"
            id="rollOver"
            value={rollOver}
            onChange={e => setRollOver(e.target.value)}
            required
          />
        </div>
        <div className={styles.userList}>
          {users.map(user => (
            <div key={user._id} className={styles.userItem}>
              <input
                type="checkbox"
                id={user._id}
                checked={selectedUsers.includes(user._id)}
                onChange={() => handleUserSelect(user._id)}
              />
              <label htmlFor={user._id}>{user.username}</label>
            </div>
          ))}
        </div>
        <button type="submit" className={styles.submitButton}>
          Add Promotion
        </button>
      </form>
      <Link to="/admin" className={styles.backButton}>
        Back to Admin Home
      </Link>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default Promotions;
