import React, {useEffect, useState, useContext} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import axios from 'axios';
import {AuthContext} from '../../context/auth.context';
import SubNavbar from '../../components/SubNavbar';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useDispatch, useSelector} from 'react-redux';
import {setGameId, setGameType, setCanBet} from '../../redux/actualGameSlice';
import {useTranslation} from 'react-i18next';
import styles from './doubleDetails.module.css';
import PropTypes from 'prop-types';

const API_URL = process.env.REACT_APP_API_URL;

const BetTicket = React.memo(({bet, renderBetStatus}) => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.ticket} ${styles[`ticket-${bet.status}`]}`}>
      <div className={styles.ticketHeader}>{bet.betMatch}</div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.eventDate')}: </span>
        <span className={styles.ticketValue}>
          {new Date(bet.matchTime * 1000).toLocaleString()}
        </span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.betType')}: </span>
        <span className={styles.ticketValue}>{bet.betType}</span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.bet')}: </span>
        <span className={styles.ticketValue}>{renderBetStatus(bet)}</span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.betResult')}: </span>
        <span className={styles.ticketValue}>
          {bet.result === '' ? t('myBets.betResultPending') : bet.result}
        </span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.betStatus')}: </span>
        <span className={`${styles.status} ${styles[`status-${bet.status}`]}`}>
          {bet.status}
        </span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.coinsBet')}: </span>
        <span className={styles.ticketValue}>{bet.betOdd}</span>
      </div>
    </div>
  );
});

BetTicket.propTypes = {
  bet: PropTypes.shape({
    status: PropTypes.string.isRequired,
    betMatch: PropTypes.string.isRequired,
    matchTime: PropTypes.number.isRequired,
    betType: PropTypes.string.isRequired,
    result: PropTypes.string.isRequired,
    betOdd: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
  renderBetStatus: PropTypes.func.isRequired,
};

BetTicket.displayName = 'BetTicket';

const DoubleDetails = () => {
  const {t} = useTranslation();
  const {isLoggedIn} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [doubleData, setDoubleData] = useState(null);

  const {userName, userId} = useSelector(state => state.user);
  const {gameName} = useSelector(state => state.actualGame);

  const dispatch = useDispatch();
  const {id: doubleId} = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchDoubleDetails = async () => {
      if (!isLoggedIn) return;

      try {
        const {data} = await axios.post(`${API_URL}/get-double-details`, {
          doubleId,
          userId,
        });
        setDoubleData(data);
        dispatch(setCanBet(data.canBet));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching double details:', error);
        setLoading(false);
      }
    };

    fetchDoubleDetails();
  }, [isLoggedIn, doubleId, userId, dispatch]);

  const handleSubmitForm = e => {
    e.preventDefault();
    dispatch(setGameId(doubleId));
    dispatch(setGameType('doubles'));
    history.push(`/home/doubles/${doubleId}/${gameName}/competitions`);
  };

  const renderBetStatus = bet => {
    const betSigneMap = {
      1: bet.betTeamHome,
      2: bet.betTeamAway,
      Draw: 'Draw',
      // ... rest of your betSigneMap
    };

    return betSigneMap[bet.betSigne] || bet.betSigne;
  };

  if (loading || !doubleData) {
    return <div className={styles.loading}>{t('doubleDetails.loading')}</div>;
  }

  const {doubleInfo, myBets, opponent} = doubleData;

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <SubNavbar id={doubleId} game="double" leagueName={doubleInfo?.name} />
      <div className={styles.container}>
        <h1 className={styles.title}>
          {doubleInfo.condition === 'closed'
            ? doubleInfo.userWinner === 'tie'
              ? t('doubleDetails.tieResult', {
                  amount: (doubleInfo.pot.toFixed(2) / 2).toFixed(2),
                })
              : doubleInfo.userWinner === 'cancelled'
                ? t('doubleDetails.cancelledResult')
                : t('doubleDetails.resultTitle', {
                    winner: doubleInfo.userWinner,
                    amount: doubleInfo.pot.toFixed(2),
                  })
            : t('doubleDetails.playingFor', {
                amount: doubleInfo.pot.toFixed(2),
              })}
        </h1>

        <div className={styles.playersContainer}>
          <div className={styles.playerSection}>
            <h2>{userName}</h2>
            {myBets.hasBet ? (
              <>
                <div className={styles.finalOdd}>
                  {t('doubleDetails.finalOdd')}: {myBets.finalOdd.toFixed(2)}
                </div>
                {myBets.bets.map(bet => (
                  <BetTicket
                    key={bet._id}
                    bet={bet}
                    renderBetStatus={renderBetStatus}
                  />
                ))}
              </>
            ) : doubleInfo.finishDate < Date.now() / 1000 ? (
              <div className={styles.alert}>
                {t('doubleDetails.doubleFinished')}
              </div>
            ) : (
              <form onSubmit={handleSubmitForm}>
                <button className={styles.betButton} type="submit">
                  {t('doubleDetails.bet')}
                </button>
              </form>
            )}
          </div>

          <div className={styles.playerSection}>
            <h2>
              {opponent?.username || t('doubleDetails.waitingForOpponent')}
            </h2>
            {opponent?.history && (
              <div className={styles.opponentHistory}>
                {opponent.history.lastDoubles?.map((double, index) => {
                  const historyClass = {
                    win: styles.historyWin,
                    lost: styles.historyLost,
                    tie: styles.historyTie,
                  }[double.result];

                  return (
                    <div
                      key={index}
                      className={`${styles.historyItem} ${historyClass}`}
                    >
                      {double.totalOdd}
                    </div>
                  );
                })}
              </div>
            )}
            {!myBets.hasBet ? (
              <h5>{t('doubleDetails.waitForYourBet')}</h5>
            ) : opponent ? (
              !opponent.hasBet ? (
                <h5>{t('doubleDetails.opponentNotBet')}</h5>
              ) : (
                <>
                  <div className={styles.finalOdd}>
                    {t('doubleDetails.finalOdd')}:{' '}
                    {opponent.finalOdd.toFixed(2)}
                  </div>
                  {opponent.bets.map(bet => (
                    <BetTicket
                      key={bet._id}
                      bet={bet}
                      renderBetStatus={renderBetStatus}
                    />
                  ))}
                </>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DoubleDetails;
