import {useState, useEffect} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setCoinsInLeague} from '../redux/betSlice.js';

export const useCoinsInLeagueUsers = (leagueId, isLoggedIn, userId) => {
  const [usersCoinsInfo, setUsersCoinInfo] = useState(null);
  const [loading2, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/leagues/${leagueId}`)
        .then(response => {
          const user = response.data.find(user => user?.userId === userId);
          const formatCoins = coins =>
            Number.isInteger(coins) ? coins : coins.toFixed(2);
          const formattedCoins = user && formatCoins(user?.coinsInLeague);
          setUsersCoinInfo(response.data);
          dispatch(setCoinsInLeague(formattedCoins));
          setLoading(false);
        });
    }
  }, [isLoggedIn, leagueId]);

  return {usersCoinsInfo, loading2};
};
